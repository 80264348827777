import {FC} from 'react';
import {PodiumUserDataItem} from "../../LeaderboardPodium";
import PodiumAvatar from "../PodiumAvatar/PodiumAvatar";
import styles from "./PodiumUser.module.scss";
import PodiumScoreIndicator from "../PodiumScoreIndicator/PodiumScoreIndicator";

interface PodiumUserProps {
	userData: PodiumUserDataItem
}

const PodiumUser: FC<PodiumUserProps> = ({userData}) => {
	const isFirst = userData.rank === 1;

	return (
		<div style={{paddingTop: isFirst ? 25 : 65}} className={styles.podiumUser}>
			<PodiumAvatar podiumPlace={userData.rank} color={userData.color} avatarUrl={userData.avatarUrl}/>
			<div className={styles.labelsContainer}>
				<div className={styles.label}>{userData.userName}</div>
				<PodiumScoreIndicator isFirst={isFirst} userData={userData}/>
			</div>
		</div>
	)
}

export default (PodiumUser);