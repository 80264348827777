import React, {FC, memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AsyncStatus} from 'constants/http';
import NothingToShow from './components/NothingToShow/NothingToShow';

import './Table.scss';
import {LeaderboardTableHeaderType} from "../../../../constants/leaderboard";
import LeaderboardTableHeader from "../../../../models/LeaderboardTableHeader";
import LeaderboardTableRow from "./components/LeaderboardTableRow/LeaderboardTableRow";

export type LeaderboardRow = { isHighlighted: boolean; row: Record<string, unknown> };

interface LeaderboardTableViewProps {
	rows: LeaderboardRow[];
	leaderboardDataFetchingStatus: AsyncStatus;
	leaderboardsListFetchingStatus: AsyncStatus;
	renderSkeleton: (columns: Array<JSX.Element>) => React.ReactNode;
	headers: LeaderboardTableHeader[];
}

export const leaderboardTableColumnsClassNamesMap: { [key in LeaderboardTableHeaderType]: string } = {
	[LeaderboardTableHeaderType.RANK]: "rank-column",
	[LeaderboardTableHeaderType.RANK_DIFF]: "rank-diff-column",
	[LeaderboardTableHeaderType.AVATAR]: "avatar-column",
	[LeaderboardTableHeaderType.USERNAME]: "username-column",
	[LeaderboardTableHeaderType.PEAK_RANK]: "peak-rank-column",
	[LeaderboardTableHeaderType.COUNTRY]: "country-column",
	[LeaderboardTableHeaderType.NONE]: "none-column",
}

const LeaderboardTableView: FC<LeaderboardTableViewProps> = ({
	rows,
	leaderboardDataFetchingStatus,
	leaderboardsListFetchingStatus,
	renderSkeleton,
	headers,
}) => {
	const {t} = useTranslation();

	const tableColumns = useMemo(() =>
		headers.map(header =>
			<th key={header.displayColumnIndex}
				className={`l-table-column-name ${leaderboardTableColumnsClassNamesMap[header.type]}`}
			>{t(
				header.name)}</th>
		), [t, headers]);

	const tableRows = useMemo(() =>
		rows
			.map(({isHighlighted, row}, idx) => {
				return <LeaderboardTableRow key={idx} row={row} isHighlighted={isHighlighted} rowIndex={idx} headers={headers}/>
			}), [headers, rows]);

	const tableContent = useMemo(() => !!tableRows.length ? (
			<table className='l-table'>
				<thead className='l-table-head'>
				<tr className='l-table-row'>{tableColumns}</tr>
				</thead>
				<tbody>{tableRows}</tbody>
			</table>
		) : <NothingToShow/>,
		[tableColumns, tableRows]
	);

	return (
		<div className='l-table-wrapper'>
			{(leaderboardsListFetchingStatus === AsyncStatus.Pending ||
				(leaderboardDataFetchingStatus === AsyncStatus.Pending && tableColumns)) ?
				renderSkeleton(tableColumns) : tableContent}
		</div>
	)
}

export default memo(LeaderboardTableView);