import {FC} from 'react';
import CircularProgressBar from "../../../../../../../../../../components/CircularProgressBar/CircularProgressBar";
import styles from "./progressTrackerFinalScoreCircle.module.scss"


interface ProgressTrackerFinalScoreCircleProps {
	value: number,
	circleColor?: string,
	strokeColor?: string,
	textColor?: string,
	playerProgressInPercents: number
}

const ProgressTrackerFinalScoreCircle: FC<ProgressTrackerFinalScoreCircleProps> = ({
	value,
	circleColor,
	playerProgressInPercents,
	textColor,
	strokeColor
}) => {

	return (
		<div className={styles.circleContainer}>
			<CircularProgressBar
				circleColor={circleColor ?? "white"}
				strokeColor={strokeColor ?? "#9013FE"}
				progress={playerProgressInPercents}
				circleSize={82}
				strokeWidth={7}/>
			<div style={{color: textColor}} className={styles.value}>
				{value}
			</div>
		</div>
	)
}

export default (ProgressTrackerFinalScoreCircle);