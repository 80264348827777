import {LeaderboardsListItemApiDto, LeaderboardTableHeadersToDisplayApiDto} from "../interfaces/restModels";
import LeaderboardTableHeader from "./LeaderboardTableHeader";
import {LeaderboardType} from "../constants/leaderboard";
import {Dayjs} from "dayjs";
import {convertStringToDayjs} from "../utils/date";
import LeaderboardDisplayHeader from "./LeaderboardDisplayHeader";


export default class LeaderboardsListItem {
	constructor(
		public readonly id: string,
		public readonly tableHeadersToDisplay: LeaderboardTableHeader[],
		public readonly mobileTableHeadersToDisplay: LeaderboardTableHeader[],
		public readonly title: string,
		public readonly leaderboardType: LeaderboardType,
		public readonly startTime: Dayjs,
		public readonly endTime: Dayjs,
		public readonly updatedAt: Dayjs,
		public readonly leaderboardDisplayHeader: LeaderboardDisplayHeader,
		public readonly styleTags: string[]
	) {}

	get showPodium(): boolean {
		return this.styleTags.includes("podium")
	}

	private static getTableHeadersToDisplay(fetchedHeadersToDisplay: LeaderboardTableHeadersToDisplayApiDto[],
		headers: string[]): LeaderboardTableHeader[] {
		return fetchedHeadersToDisplay
			.map(item => {
				const correspondingEntryIndex = headers.indexOf(item.correspondingHeaderName!);
				const correspondingEntryIndexToSet = correspondingEntryIndex === -1 ? null : correspondingEntryIndex;
				return LeaderboardTableHeader.fromJson(item, correspondingEntryIndexToSet)
			})
			.sort((a, b) => a.displayColumnIndex - b.displayColumnIndex)
	}

	public static fromJson(json: LeaderboardsListItemApiDto): LeaderboardsListItem {
		const tableHeadersToDisplay = LeaderboardsListItem.getTableHeadersToDisplay(json.headersToDisplay ?? [], json.headers)
		const mobileTableHeadersToDisplay = LeaderboardsListItem.getTableHeadersToDisplay(json.mobileHeadersToDisplay ?? [],
			json.headers)

		const leaderboardType = LeaderboardType[json.leaderboardType]
		const styleTags = json.styleTags.map(item => item.toLowerCase());
		return new LeaderboardsListItem(json.id, tableHeadersToDisplay, mobileTableHeadersToDisplay, json.title, leaderboardType,
			convertStringToDayjs(json.startTime),
			convertStringToDayjs(json.endTime),
			convertStringToDayjs(json.updatedAt),
			LeaderboardDisplayHeader.fromJson(json.leaderboardDisplayHeader),
			styleTags
		)
	}
}