import {FC} from "react";
import Skeleton from "../../../Skeleton";
import styles from './LeaderboardTableSkeleton.module.scss';
import classNames from "classnames";
import {LeaderboardPagination} from "constants/leaderboard";

interface SkeletonTableProps {
	className?: string;
	rows?: number;
}

const ROW_HEIGHT = 72;

const LeaderboardTableSkeleton: FC<SkeletonTableProps> = ({className, rows = LeaderboardPagination.Size}) => {
	return (
		<div className={classNames(styles.root, className)}>
			<Skeleton
				style={{height: `${ROW_HEIGHT * rows}px`}}
				className={styles.skeleton}
			/>
		</div>
	);
}

export default LeaderboardTableSkeleton;