import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import React from "react";

interface RankDiffTableCellContentProps {
	value: string,
	styles: { [p: string]: string }
}

export const RankDiffTableCellContent: React.FC<RankDiffTableCellContentProps> = ({value, styles}) => {
	const rankDiffValue = parseInt(value);
	const rankToDisplay = rankDiffValue > 9999 ? "9999+" : Math.abs(rankDiffValue);
	const rankDiffClassName = rankDiffValue === 0 ?
		'withoutChanging' :
		rankDiffValue > 0 ?
			'arrow-up' :
			'arrow-down';

	return (
		<TableCellContent className={styles.rankDiffCell}>
			<div className={styles.rankDiffCell}>
				<div className={styles[rankDiffClassName]}/>
				{!!rankDiffValue && <span>{rankToDisplay}</span>}
			</div>
		</TableCellContent>
	);
}