import {AppSettingsApiDto} from "../interfaces/restModels";

export default class AppSettings {
	constructor(
		public readonly baseUrl: string,
	) {}

	public static fromJson(json: AppSettingsApiDto): AppSettings | never {
		const {baseUrl} = json;
		if (!baseUrl) {
			throw new Error("Incomplete settings.");
		}
		return new AppSettings(baseUrl);
	}
}