import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as AgletLogoIcon } from 'assets/images/aglet-logomark.svg';
import { ReactComponent as DiscordIcon } from 'assets/icons/discord.icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.icon.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.icon.svg';
import LanguageSelect from 'components/Select/components/LanguageSelect/LanguageSelect';
import { footerSections, FooterSectionType } from 'config/layout/footer';

import styles from './Footer.module.scss';

interface FooterProps {
  className?: string;
}

const renderIcons: { [key: string]: () => JSX.Element } = {
  discord: () => <DiscordIcon />,
  instagram: () => <InstagramIcon />,
  twitter: () => <TwitterIcon />,
};

const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation();

  const renderSectionNavigationItems = useCallback((sections: Array<FooterSectionType>) =>
    sections.map(({ link, translation, icon }) => (
      <li key={link} className={styles.sectionGroupNavItem}>
        <a
          className={styles.sectionGroupNavLink}
          target="_blank"
          href={link}
          rel="noreferrer"
        >
          {icon && renderIcons.hasOwnProperty(icon) && renderIcons[icon]()}
          <span>{t(translation)}</span>
        </a>
      </li>
    )), [t]);

  return (
    <footer className={classNames(styles.root, className)}>
      <div className={styles.wrapper}>
        <section className={styles.section}>
          <div className={styles.sectionGroup}>
            <div className={styles.sectionGroupTitle}>{t(footerSections.company.title)}</div>
            <nav className={styles.sectionGroupNav}>
              {renderSectionNavigationItems(footerSections.company.sections)}
            </nav>
          </div>
          <div className={styles.sectionGroup}>
            <div className={styles.sectionGroupTitle}>{t(footerSections.resources.title)}</div>
            <nav className={styles.sectionGroupNav}>
              {renderSectionNavigationItems(footerSections.resources.sections)}
            </nav>
          </div>
          <div className={styles.sectionGroup}>
            <div className={styles.sectionGroupTitle}>{t(footerSections.privacy.title)}</div>
            <nav className={styles.sectionGroupNav}>
              {renderSectionNavigationItems(footerSections.privacy.sections)}
            </nav>
          </div>
          <div className={styles.sectionGroup}>
            <div className={styles.sectionGroupTitle}>{t(footerSections.followUs.title)}</div>
            <nav className={styles.sectionGroupNav}>
              {renderSectionNavigationItems(footerSections.followUs.sections)}
            </nav>
          </div>
        </section>
        <section className={styles.logoSection}>
          <LanguageSelect className={styles.logoSectionSelectLanguage} />
          <AgletLogoIcon />
        </section>
      </div >
    </footer >
  )
}

export default memo(Footer);