import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './TableCellContent.module.scss';

interface TableCellContentProps {
  className?: string;
  children: ReactNode;
}

const TableCellContent: FC<TableCellContentProps> = ({ className, children }) => {
  return (
    <div className={classNames(styles.root, className)}>{children}</div>
  )
}

export default TableCellContent;