import { FC } from "react";
import classNames from "classnames";

import styles from './Option.module.scss';

interface OptionProps {
  className?: string;
  value: string | number;
  onClick: () => void;
}

const Option: FC<OptionProps> = ({ className, value, onClick }) => {
  return (
    <li
      className={classNames(styles.root, className)}
      onClick={onClick}
    >
      {value}
    </li>
  )
}

export default Option;