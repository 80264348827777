import {FC, memo} from "react";
import MobileTableView from "./table/MobileTableView";
import {useSelector} from "react-redux";
import {selectLeaderboardMode} from "../../../../../../store/leaderboard/leaderboard.selectors";
import {LeaderboardMode} from "../../../../../../constants/leaderboard";
import ProgressTracker from "./progressTracker/ProgressTracker";


const MobileView: FC = () => {
	const leaderboardMode = useSelector(selectLeaderboardMode);

	return (
		leaderboardMode === LeaderboardMode.TABLE ? <MobileTableView/> : <ProgressTracker/>
	)
}

export default memo(MobileView);