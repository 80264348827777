import classNames from "classnames";
import {FC, memo, useCallback} from "react";

import styles from './RenderOption.module.scss';
import LeaderboardTableHeader from "../../../../../models/LeaderboardTableHeader";
import {Countries, Country} from "../../../../../constants/countries";
import CountryFlag from "../../../../CountryFlag/CountryFlag";
import CountryTooltip from "../../../../Tooltip/components/CountryTooltip/CountryTooltip";
import {LeaderboardTableHeaderType} from "../../../../../constants/leaderboard";
import {getRoundedNumberWithSeparatorFromString, isDefined} from "../../../../../utils/format";
import {getValueFromColumns} from "../../../../../pages/Home/screens/leaderboard/views/leaderboardTableUtils";
import UserAvatarImage from "../../../../UserAvatarImage/UserAvatarImage";

interface RenderOptionProps {
	className?: string;
	isActive: boolean;
	params?: { rankChange: number, peakRank: number, rank: number, columns: string[] };
	value: string | number;
	label: string;
	onClick: (data: any) => void;
	headers: LeaderboardTableHeader[]
}

const RenderOption: FC<RenderOptionProps> = ({
	className,
	params,
	value,
	label,
	isActive,
	onClick,
	headers
}) => {
	const handleClick = useCallback(() => onClick && onClick({
		select: value,
		search: label,
	}), [label, value, onClick]);

	const rankChange = params?.rankChange;
	const peakRank = params?.peakRank;
	const rank = params?.rank;

	const countryCode = params && getValueFromColumns(LeaderboardTableHeaderType.COUNTRY, params.columns, headers);
	const userName = params && getValueFromColumns(LeaderboardTableHeaderType.USERNAME, params.columns, headers);
	const scoreValue = params && getValueFromColumns(LeaderboardTableHeaderType.NONE, params.columns, headers);
	const displayedScoreValue = scoreValue && getRoundedNumberWithSeparatorFromString(scoreValue);
	const avatarUrl = params && getValueFromColumns(LeaderboardTableHeaderType.AVATAR, params.columns, headers);


	const rankDiffClassName = rankChange === 0 ?
		'withoutChanging' :
		rankChange as number > 0 ?
			'arrow-up' :
			'arrow-down';

	return isDefined(params) ? (
		<li
			data-id={value}
			className={
				classNames(
					styles.option,
					className,
					{[styles.activeOption]: isActive}
				)
			}
			onClick={handleClick}
		>
			<div className={classNames(styles.column, styles.rankColumn)}>{rank}</div>
			<div className={classNames(styles.column, styles.rankDiffColumn)}>
				<div className={styles[rankDiffClassName]}/>
				{!!rankChange && <span>{Math.abs(rankChange as number)}</span>}
			</div>
			<div className={classNames(styles.column, styles.userSectionColumn)}>
				{isDefined(avatarUrl) && <div className={styles.userAvatarColumn}>
					<UserAvatarImage
						className={styles.userAvatarImg}
						src={avatarUrl}
						alt="avatar"
						loading="lazy"
					/>
				</div>}
				{isDefined(userName) && <div className={styles.userDescriptionColumn}>
					<div className={styles.userName}>{userName}</div>
					{isDefined(peakRank) && <div className={styles.rankPeak}>{`Peak Rank ${peakRank}`}</div>}
				</div>}
			</div>
			{isDefined(countryCode) && <div className={classNames(styles.column, styles.countryColumn)}>
				{
					Countries[value as Country]?.name && <CountryTooltip
						className={styles.countryTooltip}
						title={Countries[countryCode as Country]?.name}
					/>
				}
				<CountryFlag shortCode={countryCode as Country}/>
			</div>}
			{isDefined(displayedScoreValue) && <div className={classNames(styles.column, styles.pointsColumn)}>
				{displayedScoreValue}
			</div>}
		</li>
	) : null
}

export default memo(RenderOption);