import {createContext, useContext} from 'react';
import dayjs, {Dayjs} from "dayjs";

export type LeaderboardContextType = {
	deviceWidth: number | null;
	recalculationDate: Dayjs;
}

export const LeaderboardContext = createContext<LeaderboardContextType>({
	recalculationDate: dayjs(undefined),
	deviceWidth: null,
});

export const useLeaderboardContext = () => {
	return useContext(LeaderboardContext);
}