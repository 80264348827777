import {FC} from 'react';
import UserAvatarImage from "../../../../../../../../components/UserAvatarImage/UserAvatarImage";
import styles from "./PodiumAvatar.module.scss"
import classNames from "classnames";
import CrownIcon from 'assets/icons/crown.png';

interface PodiumAvatarProps {
	avatarUrl: string;
	color: string;
	podiumPlace: number;
}

const PodiumAvatar: FC<PodiumAvatarProps> = ({avatarUrl, color, podiumPlace}) => {
	const isFirst = podiumPlace === 1;
	const size = isFirst ? 86 : 65
	const borderWidth = isFirst ? 4 : 3;

	return (
		<div className={styles.container} style={{height: size, width: size, borderWidth, borderColor: color}}>
			{isFirst && <img className={styles.crown} src={CrownIcon} alt={"Golden crown"}/>}
			<UserAvatarImage src={avatarUrl} className={styles.avatar} alt={"Podium avatar image"} loading={"lazy"}/>
			<div style={{backgroundColor: color}} className={
				classNames(styles.podiumPlaceLabelContainer,
					isFirst ? styles.firstPlaceLabelContainer : styles.otherPlacesLabelContainer)}>
				<span className={isFirst ? undefined : styles.podiumPlaceLabel}>{podiumPlace}</span>
			</div>
		</div>)
}

export default (PodiumAvatar);