import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import desktopViewStyles from "../../views/desktop/DesktopView.module.scss";
import React from "react";
import UserAvatarImage from "../../../../../../components/UserAvatarImage/UserAvatarImage";

interface AvatarTableCellContentProps {
	value: string
}

export const AvatarTableCellContent: React.FC<AvatarTableCellContentProps> = ({value}) => {
	return (
		<TableCellContent
			className={desktopViewStyles.avatarCell}>
			<div className={desktopViewStyles.playerCellContainer}>
				<div className={desktopViewStyles.userAvatarColumn}>
					<UserAvatarImage className={desktopViewStyles.userAvatarImg}
									 src={value}
									 alt="avatar"
									 loading="lazy"/>
				</div>
			</div>
		</TableCellContent>
	);
}