import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import React from "react";
import {getRoundedNumberWithSeparatorFromString} from "../../../../../../utils/format";

interface UnrecognizedTableCellContentProps {
	value: string,
	styles: { [p: string]: string }
}

export const UnrecognizedTableCellContent: React.FC<UnrecognizedTableCellContentProps> = ({value, styles}) => {
	const displayedValue = getRoundedNumberWithSeparatorFromString(value);
	return (
		<TableCellContent
			className={styles.unrecognizedValueCell}>
          <span className={styles.tableCellRankThresholdIncluded}>
            {displayedValue}
          </span>
		</TableCellContent>
	);
}