export const navigationItems = [
  {
    link: 'https://help.aglet.app/en',
    translation: 'header.navigation.item2',
  },
  {
    link: 'https://blog.aglet.app',
    translation: 'header.navigation.item3',
  },
  {
    link: 'https://blog.aglet.app/tagged/events',
    translation: 'header.navigation.item4',
  },
  {
    link: 'https://shop.aglet.app',
    translation: 'header.navigation.item5',
  },
];