import {FC, memo, useMemo} from "react";
import classNames from "classnames";
import styles from "./MobileFallingAnimation.module.scss";
import commonStyles from "../CommonFallingItems.module.scss";
import AgletSneakerSrc from "../../../assets/fx/The_Aglet_One_OG_80.png";
import {ReactComponent as AgletIcon} from 'assets/fx/aglet_icon.svg';
import {FallingAnimationItems} from "../FallingAnimation";

interface MobileFallingAnimationProps {
	logoCount: number;
	sneakersCount: number;
	customItems: string[];
	fallingAnimationItems: FallingAnimationItems
}

const MobileFallingAnimation: FC<MobileFallingAnimationProps> = ({
	sneakersCount,
	customItems,
	logoCount,
	fallingAnimationItems
}) => {


	const renderFallingItemsFromArray = (
		items: JSX.Element[],
		customClassName: string) => {

		return items
			.map((item, idx) => (
				<div
					key={idx}
					className={classNames(styles.fallingItem, customClassName)}>
					{item}
				</div>
			))
	}

	const customItemsElements = useMemo(() => {
		return customItems.map(item => <img key={item} src={item} alt='falling item'/>)
	}, [customItems])

	const fallingLogos = useMemo(() => {
		return renderFallingItemsFromArray(
			Array(logoCount).fill(<AgletIcon/>),
			commonStyles.commonFallingItem)
	}, [logoCount])

	const fallingSneakers = useMemo(() => {
		return renderFallingItemsFromArray(
			Array(sneakersCount).fill(<img src={AgletSneakerSrc} alt='sneakers'/>),
			commonStyles.commonFallingItem)
	}, [sneakersCount])

	const fallingCustomItemsSmall = useMemo(() => {
		return renderFallingItemsFromArray(
			customItemsElements,
			commonStyles.fallingItemSmall)
	}, [customItemsElements])

	const fallingCustomItemsMedium = useMemo(() => {
		return renderFallingItemsFromArray(
			customItemsElements,
			commonStyles.fallingItemMedium)
	}, [customItemsElements])

	const fallingCustomItemsLarge = useMemo(() => {
		return renderFallingItemsFromArray(
			customItemsElements,
			commonStyles.fallingItemLarge)
	}, [customItemsElements])

	return (
		<>
			{fallingAnimationItems.includes("logo") && fallingLogos}
			{fallingAnimationItems.includes("sneakers") && fallingSneakers}
			{fallingAnimationItems.includes("customSmall") && fallingCustomItemsSmall}
			{fallingAnimationItems.includes("customMedium") && fallingCustomItemsMedium}
			{fallingAnimationItems.includes("customLarge") && fallingCustomItemsLarge}
		</>
	)
}

export default memo(MobileFallingAnimation)