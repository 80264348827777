import {FC} from 'react';
import styles from './LeaderboardHeader.module.scss';
import LeaderboardsListItem from "../../../../../../models/LeaderboardsListItem";
import {LeaderboardDisplayHeaderItemType} from "../../../../../../constants/leaderboard";
import LeaderboardDisplayHeaderItemData from "../../../../../../models/LeaderboardDisplayHeaderItemData";

export enum LeaderboardHeaderImagesSize {
	SMALL = "SMALL",
	MEDIUM = "MEDIUM",
	LARGE = "LARGE"
}

interface Props {
	displayedLeaderboard: LeaderboardsListItem,
	imagesSize: LeaderboardHeaderImagesSize
}

const LeaderboardHeader: FC<Props> = ({displayedLeaderboard, imagesSize}) => {
	const leaderboardHeader = displayedLeaderboard.leaderboardDisplayHeader
	const headerTitleImageData = leaderboardHeader.getItemData(LeaderboardDisplayHeaderItemType.TITLE_IMAGE)
	const headerRulesImageData = leaderboardHeader.getItemData(LeaderboardDisplayHeaderItemType.RULES_IMAGE)
	const headerTextImageData = leaderboardHeader.getItemData(LeaderboardDisplayHeaderItemType.RULES_TEXT)


	const getImageUrl = (size: LeaderboardHeaderImagesSize,
		imageData: LeaderboardDisplayHeaderItemData | undefined): string | undefined => {
		if (!imageData) {
			return undefined;
		}

		return {
			[LeaderboardHeaderImagesSize.LARGE]: imageData.largeImageUrl ?? imageData.mediumImageUrl,
			[LeaderboardHeaderImagesSize.MEDIUM]: imageData.mediumImageUrl ?? imageData.largeImageUrl,
			[LeaderboardHeaderImagesSize.SMALL]: imageData.smallImageUrl ?? imageData.mediumImageUrl ?? imageData.largeImageUrl
		}[size]
	}

	const titleImageUrl = getImageUrl(imagesSize, headerTitleImageData);
	const rulesImageUrl = getImageUrl(imagesSize, headerRulesImageData);


	return (
		<header className={styles.header}
				style={{background: leaderboardHeader.backgroundColour ?? "transparent"}}>
			{titleImageUrl && <img className={styles.LeaderboardTitleImage} src={titleImageUrl} alt={"Leaderboard title image"}/>}
			{rulesImageUrl && <img className={styles.LeaderboardRulesImage} src={rulesImageUrl} alt={"Leaderboard rules image"}/>}
			{headerTextImageData?.text &&
				<div style={{
					color: headerTextImageData.textColour,
					fontSize: (headerTextImageData.fontSize ?? "12") + "px"
				}}
					 className={styles.leaderboardRulesText}>{headerTextImageData.text}</div>}
		</header>
	)
}

export default (LeaderboardHeader);