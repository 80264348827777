import {FC, RefObject} from 'react';
import styles from "./verticalProgressBar.module.scss";
import DefaultProgressBarImage from "../../../../../../../../../config/progressTrackers/default/images/defaultProgressBar.png"
import {AvatarDecorationConfig} from "../../../../../../../../../config/progressTrackers/activeProgressTrackersConfigs";
import UserAvatarImage from "../../../../../../../../../components/UserAvatarImage/UserAvatarImage";
import {useSelector} from "react-redux";
import {selectUserDataInfo} from "../../../../../../../../../store/leaderboard/leaderboard.selectors";

interface HorizontalProgressBarProps {
	progressBarImage?: string,
	avatarDecorationConfig?: AvatarDecorationConfig,
	progressBarRef: RefObject<HTMLDivElement>
}

const HorizontalProgressBar: FC<HorizontalProgressBarProps> = ({progressBarImage, avatarDecorationConfig, progressBarRef}) => {
	const {userData} = useSelector(selectUserDataInfo);
	const avatarDecorationStyles = avatarDecorationConfig &&
		{
			width: avatarDecorationConfig.size,
			left: `calc(50% - ${avatarDecorationConfig.size / 2}px)`,
			bottom: avatarDecorationConfig.bottom
		}


	return (
		<div ref={progressBarRef} className={styles.verticalProgressBar}>
			<img alt={"Bar pointing player position"} className={styles.barImage}
				 src={progressBarImage ?? DefaultProgressBarImage}/>
			{avatarDecorationConfig &&
				<img alt={"User avatar decoration"} style={avatarDecorationStyles} className={styles.avatarDecorationImage}
					 src={avatarDecorationConfig.path}/>}
			<UserAvatarImage loading={"eager"} alt={"User avatar"} className={styles.avatarImage} src={userData!.avatarUrl}/>
		</div>)
}

export default (HorizontalProgressBar);