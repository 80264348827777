import {FC, memo} from "react";
import {useLeaderboardContext} from "../../contexts/leaderboard.context";

import styles from './DesktopView.module.scss';
import {useTranslation} from "react-i18next";
import DesktopLeaderboardTable from "./components/leaderboard/DesktopLeaderboardTable";
import {getFormattedDateString} from "../../../../../../utils/date";
import SearchBar from "../../components/SearchBar/SearchBar";
import {useSelector} from "react-redux";
import {selectDisplayedLeaderboard} from "../../../../../../store/leaderboard/leaderboard.selectors";
import {useViewport} from "../../../../../../hooks/useViewport";
import {ScreenViewBreakpoints} from "../../../../../../constants/layout";
import LeaderboardHeader, {LeaderboardHeaderImagesSize} from "../../components/LeaderboardHeader/LeaderboardHeader";


const DesktopView: FC = () => {
	const {recalculationDate} = useLeaderboardContext();
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard);
	const {t} = useTranslation();
	const {viewport} = useViewport();
	const isTabletOrSmallDesktop = viewport.width < ScreenViewBreakpoints.TabletsAndSmallDesktops
	const imagesSize = isTabletOrSmallDesktop ? LeaderboardHeaderImagesSize.MEDIUM : LeaderboardHeaderImagesSize.LARGE;

	return (
		<div className={styles.root}>
			{displayedLeaderboard &&
				<LeaderboardHeader displayedLeaderboard={displayedLeaderboard} imagesSize={imagesSize}/>}
			<section className={styles.searchBarSection}>
				{recalculationDate.isValid() && <div className={styles.latestRecalculationDate}>
					{`${t("main.leaderboardView.recalculationDateLabel")} ${getFormattedDateString(recalculationDate)}`}
				</div>}
				{displayedLeaderboard && <SearchBar/>}
			</section>
			<section className={styles.tabContent}>
				<DesktopLeaderboardTable isTabletOrSmallDesktop={isTabletOrSmallDesktop}/>
			</section>
		</div>
	);
}

export default memo(DesktopView);