import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import {formatNumber} from "../../../../../../utils/format";
import React from "react";

interface RankTableCellContentProps {
	value: string
	styles:  {[p: string]: string}
}

export const RankTableCellContent: React.FC<RankTableCellContentProps> = ({value, styles}) => {

	return (
		<TableCellContent className={styles.rankCell}>
          <span className={styles.tableCellRank}>
            {formatNumber(parseInt(value))}
          </span>
		</TableCellContent>
	);
}