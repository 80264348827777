import React, {FC, memo} from "react";
import {ReactComponent as CloseIcon} from 'assets/icons/close.icon.svg';
import {ReactComponent as SearchIcon} from 'assets/icons/search-magnifier.icon.svg';

import classNames from "classnames";
import styles from './Leaderboard.module.scss';

interface LeaderboardAutoCompleteProps {
	children?: React.ReactNode;
	placeholder?: string;
	className?: string;
	isDisabled?: boolean;
	value: string;
	onChange: (value: any) => void;
	onKeyDown: (value: any) => void;
	onClose: () => void;
}

const LeaderboardAutoComplete: FC<LeaderboardAutoCompleteProps> = ({
	placeholder,
	className,
	value,
	isDisabled = false,
	onChange,
	onClose,
	onKeyDown,
	children
}) => {
	return (
		<>
			<div className={classNames(styles.root, className)}>
				<input
					type="text"
					className={styles.searchInput}
					placeholder={placeholder}
					value={value}
					disabled={isDisabled}
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
				<div className={styles.iconWrapper}>
					{!value.length ? (
						<SearchIcon className={styles.searchIcon}/>
					) : (
						<CloseIcon
							onClick={onClose}
							className={(styles.closeIcon)}
						/>
					)}
				</div>
			</div>
			{children}
		</>
	)
}

export default memo(LeaderboardAutoComplete);