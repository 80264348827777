import {memo, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AccessMode, LeaderboardMode} from "constants/leaderboard";
import Leaderboard from "./screens/leaderboard/Leaderboard";

import styles from './Root.module.scss';
import {loadLeaderboardsList, loadUserDataByToken, setEntry, setLeaderboardMode} from "../../store/leaderboard/leaderboard.slice";
import {selectLeaderboardCommonEntities} from "../../store/leaderboard/leaderboard.selectors";
import {ScreenView} from "../../constants/layout";


const Root = () => {
	const dispatch = useDispatch();
	const {screenView} = useSelector(selectLeaderboardCommonEntities);

	const identifyTheEntryPointToTheApp = useCallback(() => {
		const urlQuery = window.location.search;
		const params = new URLSearchParams(urlQuery);
		const userName = params.get('user-name');
		const userToken = params.get('token');
		const isRedirected = userName && userToken && screenView === ScreenView.Mobile;

		if (isRedirected) {
			dispatch(setEntry({accessMode: AccessMode.Redirected, userName: userName, userToken}));
			dispatch(loadUserDataByToken({userToken}));
		}
		else {
			dispatch(setEntry({accessMode: AccessMode.Directly}));

		}
		//const modeToSet = isRedirected ? LeaderboardMode.PROGRESS_TRACKER : LeaderboardMode.TABLE;
		const modeToSet = LeaderboardMode.TABLE
		dispatch(setLeaderboardMode(modeToSet))

	}, [dispatch, screenView]);

	useEffect(() => {
		dispatch(loadLeaderboardsList({}));
	}, [dispatch])


	useEffect(() => {
		identifyTheEntryPointToTheApp();
	}, [identifyTheEntryPointToTheApp]);

	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<Leaderboard/>
			</div>
		</div>
	)
}

export default memo(Root);