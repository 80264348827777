// Examples:
// {firstName: 'John', lastName: 'Doe', age: undefined, licence: null, skill: ''} -> 'firstName=John&lastName=Doe'

import {SortDirection, SortField} from "../api/leaderboard/common.types";

export function formatObjectToQueryString<T extends Record<string, unknown>>
(object: T) {
	return Object.entries(object)
		.reduce<string[]>((acc, [key, value]) => {
			if (value === undefined || value === null || value === '') {
				return acc;
			}
			if (Array.isArray(value)) {
				return [
					...acc,
					...value.map(
						(itemValue) =>
							`${key}=${encodeURIComponent(
								typeof itemValue === 'string' ? itemValue : JSON.stringify(itemValue),
							)}`,
					),
				];
			}
			return [...acc, `${key}=${value}`];
		}, [])
		.join('&');
}

export function getLeaderboardSortQuery(field: SortField, direction: SortDirection) {
	return field + "," + direction
}