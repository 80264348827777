import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import React from "react";
import classNames from "classnames";
import {Countries, Country} from "../../../../../../constants/countries";
import CountryFlag from "../../../../../../components/CountryFlag/CountryFlag";

interface CountryTableCellContentProps {
	value: string,
	styles: { [p: string]: string },
	showCountryName?: boolean
}

export const CountryTableCellContent: React.FC<CountryTableCellContentProps> = ({value, styles, showCountryName = true}) => {
	return (
		<TableCellContent
			className={
				classNames(
					styles.countryCodeCell,
					{[styles.isNotDefinedCountry]: !Countries[value as Country]?.name}
				)
			}>
			<CountryFlag shortCode={value as Country}/>
			{showCountryName && <span className={styles.tableCellCountry}>
            {Countries[value as Country]?.name}
          </span>}
		</TableCellContent>
	);
}