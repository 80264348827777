import {CSSProperties, FC} from 'react';
import styles from "./premiumRewardContainer.module.scss";
import LockIcon from "../../../../../../../../../../../assets/icons/lock-icon.png"
import GetThePassLabelImage
	from "../../../../../../../../../../../config/progressTrackers/default/images/get-the-pass-label-image.png"
import CollectionMark from "../../../../../../../../../../../assets/icons/collection-mark-icon.png";

interface PremiumRewardContainerProps {
	rewardImagePath?: string,
	isUserPremium: boolean,
	isCollected: boolean,
	premiumShopUrl: string,
	isPremiumPurchaseEnabled: boolean
}

const PremiumRewardContainer: FC<PremiumRewardContainerProps> = ({
	rewardImagePath,
	premiumShopUrl,
	isUserPremium,
	isCollected,
	isPremiumPurchaseEnabled
}) => {
	const isBlocked = !isUserPremium && rewardImagePath;
	const blockedStyles: CSSProperties | undefined = isBlocked ? {filter: "brightness(0.3)"} : undefined
	const canBuyPremium = isPremiumPurchaseEnabled && isBlocked

	return (<div className={styles.premiumRewardContainer}>
		{rewardImagePath && <div className={styles.premiumLabelContainer}>PREMIUM</div>}
		{rewardImagePath &&
			<a href={canBuyPremium ? premiumShopUrl : undefined}>
				{isBlocked && <img className={styles.lockIcon} alt={"Lock"} src={LockIcon}/>}
				<div style={blockedStyles} className={styles.imageContainer}>
					{isCollected &&
						<img loading={"lazy"} alt={"Collection mark"} className={styles.collectionMarkImage}
							 src={CollectionMark}/>}
					<img loading={"lazy"} className={styles.rewardImage} src={rewardImagePath} alt={"Premium track reward"}/>
				</div>
			</a>
		}
		{canBuyPremium &&
			<div className={styles.getThePassButton}>
				<a href={premiumShopUrl}>
					<img loading={"lazy"} alt={"Get the pass"} src={GetThePassLabelImage}/>
				</a>
			</div>
		}
	</div>)
}

export default (PremiumRewardContainer);