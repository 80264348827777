import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccessMode, LeaderboardMode, LeaderboardPagination, SearchBarPagination} from 'constants/leaderboard';
import {AsyncStatus} from 'constants/http';
import LeaderboardEntriesData from "../../models/LeaderboardEntriesData";
import LeaderboardsListItem from "../../models/LeaderboardsListItem";
import {Country} from "../../constants/countries";
import {
	LeaderboardEntryFromUrlOptions,
	LeaderboardEntryOptions,
	LeaderboardOptions
} from "../../api/leaderboard/leaderboard.types";
import LeaderboardEntry from "../../models/LeaderboardEntry";
import {ScreenView} from "../../constants/layout";
import {Language} from "../../constants/i18n";
import {activeProgressTrackersConfigs, ProgressTrackerConfig} from "../../config/progressTrackers/activeProgressTrackersConfigs";
import UserData from "../../models/UserData";

export type DataRecordsType = {
	records: LeaderboardEntriesData | null,
	pageNumber: number | number[];
	pageSize: number;
	totalPages: number;
	error: unknown | null,
	fetchingStatus: AsyncStatus;
}

export type LeaderboardsListData = {
	list: LeaderboardsListItem[],
	fetchingStatus: AsyncStatus,
	error: unknown | null,
};

export type LeaderboardEntryFromUrlData = {
	entry: LeaderboardEntry | null,
	fetchingStatus: AsyncStatus,
	error: unknown | null
}

export type SelectedLanguageType = {
	lang: Language;
	countryShortCode: Country;
	label: string;
}

export type UserRedirectionData = {
	accessMode: AccessMode;
	userToken?: string
	userName?: string;
};

export type LeaderboardSearchBar = {
	search: string;
	selected: {
		entryId: string | null;
		pageNumber: number | null;
		countryCode: Country | null;
		error: unknown | null;
		fetchingStatus: AsyncStatus;
		entries: LeaderboardEntry[] | null;
	}
	searchedEntriesData: DataRecordsType;

}

export type UserDataInfo = {
	userData: UserData | null;
	fetchingStatus: AsyncStatus,
	error: unknown | null
}

export interface LeaderboardState {
	searchBar: LeaderboardSearchBar;
	data: DataRecordsType;
	leaderboardEntryFromUrlData: LeaderboardEntryFromUrlData
	leaderboardsListData: LeaderboardsListData;
	displayedLeaderboard: LeaderboardsListItem | null;
	displayedProgressTracker: ProgressTrackerConfig | null;
	userRedirectionData: UserRedirectionData;
	screenView: ScreenView;
	languageOptions: SelectedLanguageType;
	leaderboardMode: LeaderboardMode | null;
	userDataInfo: UserDataInfo;
}

const initialState: LeaderboardState = {
	leaderboardMode: null,
	displayedProgressTracker: null,
	searchBar: {
		search: '',
		selected: {
			entryId: null,
			pageNumber: null,
			countryCode: null,
			error: null,
			fetchingStatus: AsyncStatus.Idle,
			entries: null
		},
		searchedEntriesData: {
			records: null,
			pageNumber: SearchBarPagination.Page,
			pageSize: SearchBarPagination.Size,
			totalPages: 0,
			error: null,
			fetchingStatus: AsyncStatus.Idle,
		}
	},
	leaderboardEntryFromUrlData: {
		entry: null,
		fetchingStatus: AsyncStatus.Idle,
		error: null
	},
	data: {
		records: null,
		pageNumber: LeaderboardPagination.Page,
		pageSize: LeaderboardPagination.Size,
		totalPages: 0,
		error: null,
		fetchingStatus: AsyncStatus.Idle,
	},
	leaderboardsListData: {
		list: [],
		error: null,
		fetchingStatus: AsyncStatus.Idle
	},
	displayedLeaderboard: null,
	userRedirectionData: {
		accessMode: AccessMode.Directly,
	},
	screenView: ScreenView.Desktop,
	languageOptions: {
		lang: Language.English,
		label: 'Eng',
		countryShortCode: Country.UnitedStates,
	},
	userDataInfo: {
		userData: null,
		fetchingStatus: AsyncStatus.Idle,
		error: null,
	}
}

export const leaderboardSlice = createSlice({
	name: 'leaderboard',
	initialState,
	reducers: {
		loadLeaderboardsList: (state, action: PayloadAction<any>) => {
			state.leaderboardsListData = {
				...state.leaderboardsListData,
				fetchingStatus: AsyncStatus.Pending
			}
		},
		loadLeaderboardsListSuccess: (state, action: PayloadAction<any>) => {
			state.leaderboardsListData = {
				...state.leaderboardsListData,
				fetchingStatus: AsyncStatus.Success,
				list: action.payload
			}
			if (state.leaderboardMode === LeaderboardMode.TABLE) {
				state.displayedLeaderboard = {...action.payload[0], showPodium: action.payload[0].showPodium}
			}
			else if (state.leaderboardMode === LeaderboardMode.PROGRESS_TRACKER) {
				state.displayedProgressTracker = activeProgressTrackersConfigs[0];
			}
		},
		loadLeaderboardsListFailed: (state, action: PayloadAction<any>) => {
			state.leaderboardsListData = {
				...state.leaderboardsListData,
				fetchingStatus: AsyncStatus.Failed,
				error: action.payload
			}
		},
		setDisplayedLeaderboard: (state, action: PayloadAction<LeaderboardsListItem | null>) => {
			state.displayedLeaderboard = action.payload;
		},
		resetLeaderboardSearchBarData: (state) => {
			state.searchBar = {
				...state.searchBar,
				searchedEntriesData: initialState.searchBar.searchedEntriesData
			}
		},
		setLeaderboardSearchBarSearchValue: (state, action: PayloadAction<string>) => {
			state.searchBar = {
				...state.searchBar,
				search: action.payload
			}
		},
		setLeaderboardSearchBarSelectedValue: (state, action: PayloadAction<string | null>) => {
			if (action.payload === null) {
				state.searchBar = {
					...state.searchBar,
					searchedEntriesData: initialState.searchBar.searchedEntriesData,
					selected: initialState.searchBar.selected
				}
			}
			else {
				state.searchBar.selected = {
					...state.searchBar.selected,
					entryId: action.payload
				}
			}
		},
		loadLeaderboardEntries: (state, action: PayloadAction<LeaderboardOptions>) => {
			state.data = {
				...state.data,
				fetchingStatus: AsyncStatus.Pending
			}
		},
		loadLeaderboardEntriesSuccess: (
			state,
			action: PayloadAction<LeaderboardEntriesData | null>
		) => {
			state.data = {
				...state.data,
				records: action.payload,
				fetchingStatus: AsyncStatus.Success
			};
		},
		loadLeaderboardEntriesFailed: (state, action: PayloadAction<unknown>) => {
			state.data = {
				...state.data,
				error: action.payload,
				fetchingStatus: AsyncStatus.Failed
			};
		},

		loadLeaderboardSearchedEntries: (state, action: PayloadAction<LeaderboardOptions>) => {
			state.searchBar.searchedEntriesData.fetchingStatus = AsyncStatus.Pending;
		},
		loadLeaderboardSearchedEntriesSuccess: (
			state,
			action: PayloadAction<LeaderboardEntriesData | null>
		) => {
			state.searchBar.searchedEntriesData = {
				...state.searchBar.searchedEntriesData,
				records: action.payload,
				fetchingStatus: AsyncStatus.Success
			};
		},
		loadLeaderboardSearchedEntriesFailed: (state, action: PayloadAction<unknown>) => {
			state.searchBar.searchedEntriesData.error = action.payload;
			state.searchBar.searchedEntriesData.fetchingStatus = AsyncStatus.Failed;
		},

		loadLeaderboardSearchResultEntries: (state, action: PayloadAction<LeaderboardEntryOptions>) => {
			state.data = {
				...state.data,
				fetchingStatus: AsyncStatus.Pending
			}
		},
		loadLeaderboardSearchResultEntriesSuccess: (
			state,
			action: PayloadAction<LeaderboardEntry[]>
		) => {
			state.searchBar = {
				...state.searchBar,
				selected: {
					...state.searchBar.selected,
					entries: action.payload
				}
			};
			state.data = {
				...state.data,
				fetchingStatus: AsyncStatus.Success
			}
		},
		loadLeaderboardSearchResultEntriesFailed: (state, action: PayloadAction<unknown>) => {
			state.data = {
				...state.data,
				error: action.payload,
				fetchingStatus: AsyncStatus.Failed
			};
		},
		resetLeaderboardSearchResultEntries: (state) => {
			state.searchBar = {
				...state.searchBar,
				selected: {
					...state.searchBar.selected,
					entries: null
				}
			};
		},


		loadLeaderboardEntryFromUrl: (state, action: PayloadAction<LeaderboardEntryFromUrlOptions>) => {
			state.leaderboardEntryFromUrlData = {
				...state.leaderboardEntryFromUrlData,
				fetchingStatus: AsyncStatus.Pending
			}
		},
		loadLeaderboardEntryFromUrlSuccess: (
			state,
			action: PayloadAction<LeaderboardEntry>
		) => {
			state.leaderboardEntryFromUrlData = {
				...state.leaderboardEntryFromUrlData,
				entry: action.payload,
				fetchingStatus: AsyncStatus.Success
			};
		},
		loadLeaderboardEntryFromUrlFailed: (state, action: PayloadAction<unknown>) => {
			state.leaderboardEntryFromUrlData = {
				...state.leaderboardEntryFromUrlData,
				error: action.payload,
				fetchingStatus: AsyncStatus.Failed
			};
		},
		setEntry: (state, action: PayloadAction<UserRedirectionData>) => {
			state.userRedirectionData = action.payload;
		},
		setScreenView: (state, action: PayloadAction<ScreenView>) => {
			state.screenView = action.payload;
		},
		setLanguageFilter: (state, action: PayloadAction<SelectedLanguageType>) => {
			state.languageOptions = action.payload;
		},
		setLeaderboardMode: (state, action: PayloadAction<LeaderboardMode | null>) => {
			state.leaderboardMode = action.payload;
			if (action.payload === LeaderboardMode.TABLE) {
				state.displayedProgressTracker = null;
			}
			else if (action.payload === LeaderboardMode.PROGRESS_TRACKER) {
				state.displayedLeaderboard = null;
			}
		},
		setDisplayedProgressIndicator: (state, action: PayloadAction<ProgressTrackerConfig>) => {
			state.displayedProgressTracker = action.payload;
		},
		loadUserDataByToken: (state, action: PayloadAction<{ userToken: string }>) => {
			state.userDataInfo = {
				...state.userDataInfo,
				fetchingStatus: AsyncStatus.Pending
			}
		},
		loadUserDataByTokenSuccess: (
			state,
			action: PayloadAction<UserData>
		) => {
			state.userDataInfo = {
				...state.userDataInfo,
				userData: action.payload,
				fetchingStatus: AsyncStatus.Success
			};
		},
		loadUserDataByTokenFailed: (state, action: PayloadAction<unknown>) => {
			state.userDataInfo = {
				...state.userDataInfo,
				error: action.payload,
				fetchingStatus: AsyncStatus.Failed
			};
			state.leaderboardMode = LeaderboardMode.TABLE
		},
	}
});

export const {
	loadLeaderboardsList,
	loadLeaderboardsListSuccess,
	loadLeaderboardsListFailed,
	setDisplayedLeaderboard,
	loadLeaderboardEntries,
	loadLeaderboardEntriesSuccess,
	loadLeaderboardEntriesFailed,
	setLeaderboardSearchBarSearchValue,
	setLeaderboardSearchBarSelectedValue,
	resetLeaderboardSearchBarData,
	loadLeaderboardSearchedEntries,
	loadLeaderboardSearchedEntriesSuccess,
	loadLeaderboardSearchedEntriesFailed,
	loadLeaderboardSearchResultEntries,
	loadLeaderboardSearchResultEntriesSuccess,
	loadLeaderboardSearchResultEntriesFailed,
	resetLeaderboardSearchResultEntries,
	loadLeaderboardEntryFromUrl,
	loadLeaderboardEntryFromUrlSuccess,
	loadLeaderboardEntryFromUrlFailed,
	setEntry,
	setScreenView,
	setLanguageFilter,
	setLeaderboardMode,
	setDisplayedProgressIndicator,
	loadUserDataByToken,
	loadUserDataByTokenSuccess,
	loadUserDataByTokenFailed
} = leaderboardSlice.actions;
export default leaderboardSlice.reducer;