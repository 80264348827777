import {
	LeaderboardEntryFromUrlOptions,
	LeaderboardEntryOptions,
	LeaderboardOptions,
	LoadUserDataByTokenOptions, ProgressTrackerEventUserDataOptions,
} from "./leaderboard.types";
import {
	LeaderboardEntriesDataApiDto,
	LeaderboardEntryApiDto,
	LeaderboardsListItemApiDto, ProgressTrackerEventUserDataApiDto,
	UserDataApiDto
} from "../../interfaces/restModels";
import generateUrl from "../../utils/generate-url";
import {ApiRoutes} from "../../routes/api.routes";
import {AxiosInstance, AxiosResponse} from "axios";
import {formatObjectToQueryString} from "../../utils/query";


export default class LeaderboardApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	async fetchLeaderboardsList(): Promise<LeaderboardsListItemApiDto[]> {
		const url = generateUrl(ApiRoutes.Leaderboard.LeaderboardsList.Root);
		return this.axiosInstance
			.get(url)
			.then((value: AxiosResponse<LeaderboardsListItemApiDto[]>) => value.data);
	}

	async fetchLeaderboardEntries(options: LeaderboardOptions): Promise<LeaderboardEntriesDataApiDto> {
		const query = formatObjectToQueryString(
			{page: options.pageNumber, size: options.pageSize, sort: options.sort, search: options.search});
		const url = generateUrl(
			ApiRoutes.Leaderboard.LeaderboardEntries.Root.replace(':leaderboardId', options.leaderboardId),
			query.length ? `?${query}` : ''
		);
		return this.axiosInstance
			.get(url)
			.then((value: AxiosResponse<LeaderboardEntriesDataApiDto>) => value.data);
	}

	async fetchLeaderboardEntryNeighbours(options: LeaderboardEntryOptions): Promise<LeaderboardEntryApiDto[]> {
		const url = generateUrl(ApiRoutes.Leaderboard.LeaderboardNeighbours.Root
			.replace(':leaderboardId', options.leaderboardId)
			.replace(':entryId', options.entryId)
		);
		return this.axiosInstance
			.get(url)
			.then((value: AxiosResponse<LeaderboardEntryApiDto[]>) => value.data);
	}

	async fetchEntryBySearchText(options: LeaderboardEntryFromUrlOptions): Promise<LeaderboardEntryApiDto> {
		const query = formatObjectToQueryString(
			{exactText: options.searchText});
		const url = generateUrl(
			ApiRoutes.Leaderboard.LeaderboardEntryBySearchText.Root.replace(':leaderboardId', options.leaderboardId),
			query.length ? `?${query}` : ''
		);
		return this.axiosInstance
			.get(url)
			.then((value: AxiosResponse<LeaderboardEntryApiDto>) => value.data);
	}

	async fetchUserDataByToken(options: LoadUserDataByTokenOptions): Promise<UserDataApiDto> {
		return this.axiosInstance
			.get(ApiRoutes.User.Root, {
				headers: {
					"Authorization": `Bearer ${options.userToken}`
				}
			})
			.then((value: AxiosResponse<UserDataApiDto>) => value.data);
	}

	async fetchProgressTrackerEventUserData(options: ProgressTrackerEventUserDataOptions): Promise<ProgressTrackerEventUserDataApiDto> {
		const url = generateUrl(ApiRoutes.ProgressTrackerEvent.Results
			.replace(':contestId', options.eventId)
		);


		return this.axiosInstance
			.get(url, {
				headers: {
					"Authorization": `Bearer ${options.userToken}`
				}
			})
			.then((value: AxiosResponse<ProgressTrackerEventUserDataApiDto>) => value.data);
	}

}