export enum ScreenView {
  Mobile = 'MOBILE',
  Desktop = 'DESKTOP',
}

export enum ScreenViewBreakpoints {
  SmallTabletsAndLargeSmartphones = 576,
  SmallTablets = 768,
  TabletsAndSmallDesktops = 1024,
  LargeTabletsAndDesktops = 1180,
  LargeDesktops = 1920,
} 