import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NothingToShow.module.scss'

const NothingToShow: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>{t('main.emptyText')}</div>
  )
}

export default NothingToShow;