import {
	LeaderboardEntryFromUrlOptions,
	LeaderboardEntryOptions,
	LeaderboardOptions,
	LoadUserDataByTokenOptions, ProgressTrackerEventUserDataOptions
} from "./leaderboard.types";
import LeaderboardEntriesData from "../../models/LeaderboardEntriesData";
import LeaderboardsListItem from "../../models/LeaderboardsListItem";
import {LeaderboardEntryApiDto, LeaderboardsListItemApiDto} from "../../interfaces/restModels";
import {apiInstance} from "../api";
import LeaderboardEntry from "../../models/LeaderboardEntry";
import UserData from "../../models/UserData";
import ProgressTrackerEventUserData from "../../models/ProgressTrackerEventUserData";

class LeaderboardService {
	static _instance: LeaderboardService;

	constructor() {
		if (LeaderboardService._instance) {
			return LeaderboardService._instance
		}
		LeaderboardService._instance = this;
	}

	async getLeaderboardEntries(options: LeaderboardOptions): Promise<LeaderboardEntriesData> {
		return apiInstance.leaderboardApi
			.fetchLeaderboardEntries(options)
			.then(LeaderboardEntriesData.fromJson)
	}

	async getLeaderboardsList(): Promise<LeaderboardsListItem[]> {
		return apiInstance.leaderboardApi
			.fetchLeaderboardsList()
			.then((response: LeaderboardsListItemApiDto[]) => response.map(LeaderboardsListItem.fromJson))
	}

	async getLeaderboardEntryNeighbours(options: LeaderboardEntryOptions): Promise<LeaderboardEntry[]> {
		return apiInstance.leaderboardApi
			.fetchLeaderboardEntryNeighbours(options)
			.then((data: LeaderboardEntryApiDto[]) => data.map(LeaderboardEntry.fromJson))
	}

	async getLeaderboardEntryBySearchText(options: LeaderboardEntryFromUrlOptions): Promise<LeaderboardEntry> {
		return apiInstance.leaderboardApi
			.fetchEntryBySearchText(options)
			.then(LeaderboardEntry.fromJson)
	}

	async getUserDataByToken(options: LoadUserDataByTokenOptions): Promise<UserData> {
		return apiInstance.leaderboardApi
			.fetchUserDataByToken(options)
			.then(UserData.fromJson)
	}

	async getProgressTrackerEventUserData(options: ProgressTrackerEventUserDataOptions): Promise<ProgressTrackerEventUserData> {
		return apiInstance.leaderboardApi
			.fetchProgressTrackerEventUserData(options)
			.then(ProgressTrackerEventUserData.fromJson)
	}
}

export const leaderboardService: LeaderboardService = new LeaderboardService();