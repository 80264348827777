import {call, put, takeLatest} from '@redux-saga/core/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {
	loadLeaderboardEntries,
	loadLeaderboardEntriesFailed,
	loadLeaderboardEntriesSuccess,
	loadLeaderboardSearchResultEntries,
	loadLeaderboardSearchResultEntriesFailed,
	loadLeaderboardSearchResultEntriesSuccess,
	loadLeaderboardSearchedEntries,
	loadLeaderboardSearchedEntriesFailed,
	loadLeaderboardSearchedEntriesSuccess,
	loadLeaderboardsList,
	loadLeaderboardsListFailed,
	loadLeaderboardsListSuccess,
	loadLeaderboardEntryFromUrl,
	loadLeaderboardEntryFromUrlFailed,
	loadLeaderboardEntryFromUrlSuccess,
	loadUserDataByTokenFailed,
	loadUserDataByTokenSuccess,
	loadUserDataByToken
} from "./leaderboard.slice";
import {
	LeaderboardEntryFromUrlOptions,
	LeaderboardEntryOptions,
	LeaderboardOptions,
} from "../../api/leaderboard/leaderboard.types";
import {leaderboardService} from "../../api/leaderboard/LeaderboardService";


function* loadLeaderboardsListWorkerSaga() {
	try {
		const response = yield call(leaderboardService.getLeaderboardsList);
		yield put(loadLeaderboardsListSuccess(response));
	}
	catch (err) {
		yield put(loadLeaderboardsListFailed(err));
	}
}

function* loadLeaderboardApiLeadersWorkerSaga({payload}: PayloadAction<LeaderboardOptions>) {
	try {
		const response = yield call(leaderboardService.getLeaderboardEntries, payload);
		yield put(loadLeaderboardEntriesSuccess(response));
	}
	catch (err) {
		yield put(loadLeaderboardEntriesFailed(err));
	}
}

function* loadLeaderboardSearchedEntriesWorkerSaga({payload}: PayloadAction<LeaderboardOptions>) {
	try {
		const response = yield call(leaderboardService.getLeaderboardEntries, payload);
		yield put(loadLeaderboardSearchedEntriesSuccess(response));
	}
	catch (err) {
		yield put(loadLeaderboardSearchedEntriesFailed(err));
	}
}

function* loadLeaderboardSearchedEntryNeighboursWorkerSaga({payload}: PayloadAction<LeaderboardEntryOptions>) {
	try {
		const response = yield call(leaderboardService.getLeaderboardEntryNeighbours, payload);
		yield put(loadLeaderboardSearchResultEntriesSuccess(response));
	}
	catch (err) {
		yield put(loadLeaderboardSearchResultEntriesFailed(err));
	}
}

function* loadLeaderboardEntryFromUrlWorkerSaga({payload}: PayloadAction<LeaderboardEntryFromUrlOptions>) {
	try {
		const response = yield call(leaderboardService.getLeaderboardEntryBySearchText, payload);
		yield put(loadLeaderboardEntryFromUrlSuccess(response));
	}
	catch (err) {
		yield put(loadLeaderboardEntryFromUrlFailed(err));
	}
}

function* loadUserDataByTokenWorkerSaga({payload}: PayloadAction<{ userToken: string }>) {
	try {
		const response = yield call(leaderboardService.getUserDataByToken, payload);
		yield put(loadUserDataByTokenSuccess(response));
	}
	catch (err) {
		yield put(loadUserDataByTokenFailed(err));
	}
}

export default function* rootWatcherSaga() {
	yield takeLatest(loadLeaderboardEntries, loadLeaderboardApiLeadersWorkerSaga);
	yield takeLatest(loadLeaderboardsList, loadLeaderboardsListWorkerSaga);
	yield takeLatest(loadLeaderboardSearchedEntries, loadLeaderboardSearchedEntriesWorkerSaga);
	yield takeLatest(loadLeaderboardSearchResultEntries, loadLeaderboardSearchedEntryNeighboursWorkerSaga);
	yield takeLatest(loadLeaderboardEntryFromUrl, loadLeaderboardEntryFromUrlWorkerSaga);
	yield takeLatest(loadUserDataByToken, loadUserDataByTokenWorkerSaga);
}