import {LeaderboardEntryApiDto} from "../interfaces/restModels";

export default class LeaderboardEntry {
	constructor(
		public readonly columns: string[],
		public readonly rank: number,
		public readonly peakRank: number,
		public readonly identifyingColumn: string,
		public readonly rankChange: number,
		public readonly id: string
	) {}

	public static fromJson(json: LeaderboardEntryApiDto): LeaderboardEntry {
		return new LeaderboardEntry(json.columns, json.rank, json.peakRank, json.identifyingColumn, json.rankChange, json.id)
	}
}