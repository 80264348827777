import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import React from "react";
import classNames from "classnames";
import mobileViewStyles from "../../views/mobile/table/MobileTableView.module.scss";
import UserAvatarImage from "../../../../../../components/UserAvatarImage/UserAvatarImage";

export type UsernameMobileTableCellValue = {
	userName: string,
	rankPeak?: number,
	avatarUrl?: string
}

interface UsernameTableCellContentProps {
	value: UsernameMobileTableCellValue
}

export const UsernameTableCellContent: React.FC<UsernameTableCellContentProps> = ({value}) => {
	const {userName, rankPeak, avatarUrl} = value;
	return (
		<TableCellContent
			className={
				classNames(
					mobileViewStyles.mobileView,
					mobileViewStyles.userNameCell
				)
			}
		>
			<div className={mobileViewStyles.playerCellContainer}>
				{avatarUrl && <div className={mobileViewStyles.userAvatarContainer}>
					<UserAvatarImage
						className={mobileViewStyles.userAvatarImg}
						src={avatarUrl}
						alt="avatar"
						loading="lazy"
					/>
				</div>}
				<div className={mobileViewStyles.userDescriptionColumn}>
					<span className={mobileViewStyles.tableCellUserName}>{`${userName}`}</span>
					{rankPeak && <div className={mobileViewStyles.tableCellUserNameMeta}>
						<span className={mobileViewStyles.tableCellRankPeak}>{`Peak rank ${rankPeak}`}</span>
					</div>}
				</div>
			</div>
		</TableCellContent>
	);
}