import {FC} from 'react';
import styles from './LeaderboardPodium.module.scss';
import {useSelector} from "react-redux";
import {selectDisplayedLeaderboard} from "../../../../../../store/leaderboard/leaderboard.selectors";
import LeaderboardEntry from "../../../../../../models/LeaderboardEntry";
import {LeaderboardTableHeaderType} from "../../../../../../constants/leaderboard";
import LeaderboardEntriesData from "../../../../../../models/LeaderboardEntriesData";
import PodiumUser from "./components/PodiumUserItem/PodiumUser";

interface LeaderboardPodiumProps {
	leaderboardDataRecords: LeaderboardEntriesData
}

const podiumUserColors: { [key: number]: string } = {
	1: "#FFAA00",
	2: "#009BD6",
	3: "#00D95F"
}

export type PodiumUserDataItem = {
	rank: number;
	avatarUrl: string;
	rankChange: number,
	color: string,
	score: string,
	userName: string
}

const LeaderboardPodium: FC<LeaderboardPodiumProps> = ({leaderboardDataRecords}) => {
	const {mobileTableHeadersToDisplay} = useSelector(selectDisplayedLeaderboard)!;
	const podiumEntries: LeaderboardEntry[] = leaderboardDataRecords!.entries.slice(0, 3);

	const getUserDataValue = (type: LeaderboardTableHeaderType, entry: LeaderboardEntry) => {
		const correspondingEntryIndex = mobileTableHeadersToDisplay.find(header => header.type === type)!.correspondingEntryIndex;
		return entry.columns[correspondingEntryIndex!]
	}


	const usersData: PodiumUserDataItem[] = podiumEntries.map((entry) => {
		return {
			rank: entry.rank,
			rankChange: entry.rankChange,
			color: podiumUserColors[entry.rank],
			score: getUserDataValue(LeaderboardTableHeaderType.NONE, entry),
			avatarUrl: getUserDataValue(LeaderboardTableHeaderType.AVATAR, entry),
			userName: getUserDataValue(LeaderboardTableHeaderType.USERNAME, entry),
		}
	})

	return (<div className={styles.podium}>
		<div className={styles.stage}></div>
		<div className={styles.platform}></div>
		<div className={styles.usersContainer}>
			<PodiumUser userData={usersData[1]}/>
			<PodiumUser userData={usersData[0]}/>
			<PodiumUser userData={usersData[2]}/>
		</div>
	</div>)
}

export default (LeaderboardPodium);