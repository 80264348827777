import {LeaderboardRow} from "../../../components/Table/LeaderboardTableView";
import LeaderboardTableHeader from "../../../../../models/LeaderboardTableHeader";
import LeaderboardEntry from "../../../../../models/LeaderboardEntry";
import {leaderboardTableCellMap} from "../config/render-cell";
import {ScreenView} from "../../../../../constants/layout";
import {UsernameMobileTableCellValue} from "../config/mobile/UsernameTableCellContent";
import {LeaderboardTableHeaderType} from "../../../../../constants/leaderboard";

export type getCellValueFunctionType = (header: LeaderboardTableHeader,
	entry: LeaderboardEntry) => (string | UsernameMobileTableCellValue);

export const getRowCell = (header: LeaderboardTableHeader, entry: LeaderboardEntry,
	nextRowCellIndex: number, screenView: ScreenView, getCellValue: getCellValueFunctionType): { [p: number]: JSX.Element } => {
	const cellValue = getCellValue(header, entry);
	const newRowValue = leaderboardTableCellMap[screenView][header.type](cellValue)
	return {
		[nextRowCellIndex]: newRowValue
	}
};

export const getStyledTableRecords =
	(
		headersToDisplay: LeaderboardTableHeader[],
		tableEntries: LeaderboardEntry[] | undefined,
		screenView: ScreenView,
		selectedEntryId: string | null,
		allHeaders?: LeaderboardTableHeader[]
	): LeaderboardRow[] => {
		const records: LeaderboardRow[] = [];
		headersToDisplay.forEach((header, columnIndex) => {
			(tableEntries ?? []).forEach((entry, entryIndex) => {
				if (columnIndex === 0) {
					const isHighlighted = entry.id === selectedEntryId;
					records.push({isHighlighted: isHighlighted, row: {}})
				}
				const nextRowCellIndex = Object.values(records[entryIndex].row).length
				const getCellValue: getCellValueFunctionType = screenView === ScreenView.Desktop ? getDesktopCellValue :
					getMobileCellValue(allHeaders);
				const newRowCell = getRowCell(header, entry, nextRowCellIndex, screenView, getCellValue);
				records[entryIndex].row = {...records[entryIndex].row, ...newRowCell}
			})
		})
		return records;
	}

export function getUnindexedHeaderValue(headerType: LeaderboardTableHeaderType,
	entry: LeaderboardEntry,
	screenView: ScreenView): string | number {
	if (headerType === LeaderboardTableHeaderType.RANK) {
		return entry.rank
	}
	else if (headerType === LeaderboardTableHeaderType.RANK_DIFF) {
		return entry.rankChange
	}
	else if (headerType === LeaderboardTableHeaderType.PEAK_RANK && screenView === ScreenView.Desktop) {
		return entry.peakRank
	}
	return "";
}


const getDesktopCellValue = (header: LeaderboardTableHeader, entry: LeaderboardEntry): string => {
	if (header.correspondingEntryIndex) {
		return entry.columns[header.correspondingEntryIndex]
	}
	else {
		return getUnindexedHeaderValue(header.type, entry, ScreenView.Desktop).toString()
	}
}
const getMobileCellValue = (headers: LeaderboardTableHeader[] = []) => {
	return (header: LeaderboardTableHeader, entry: LeaderboardEntry): string | UsernameMobileTableCellValue => {
		if (header.correspondingEntryIndex) {
			return getMobileIndexedHeaderValue(header.type, entry, headers)
		}
		else {
			return getUnindexedHeaderValue(header.type, entry, ScreenView.Mobile).toString();
		}
	}
}


const getMobileIndexedHeaderValue = (headerType: LeaderboardTableHeaderType,
	entry: LeaderboardEntry,
	headers: LeaderboardTableHeader[]) => {
	const header = headers.find(h => h.type === headerType)!

	if (headerType === LeaderboardTableHeaderType.USERNAME) {
		return getMobileUsernameCellValue(entry, header.correspondingEntryIndex!, headers)
	}
	else {
		return entry.columns[header.correspondingEntryIndex!]
	}
}

const getMobileUsernameCellValue = (entry: LeaderboardEntry, correspondingEntryIndex, headers: LeaderboardTableHeader[]) => {
	const avatarHeaderCorrespondingEntryIndex = headers.find(
		header => header.type === LeaderboardTableHeaderType.AVATAR)?.correspondingEntryIndex
	const avatarUrl = avatarHeaderCorrespondingEntryIndex ? entry.columns[avatarHeaderCorrespondingEntryIndex] :
		undefined;
	return {
		userName: entry.columns[correspondingEntryIndex],
		rankPeak: entry.peakRank,
		avatarUrl: avatarUrl
	};
}

export const getValueFromColumns = (fieldType: LeaderboardTableHeaderType,
	entryColumns: string[],
	headers: LeaderboardTableHeader[]): string | null => {
	const columnIndex = headers.find(header => header.type == fieldType)?.correspondingEntryIndex;
	return columnIndex ? entryColumns[columnIndex] : null
}
