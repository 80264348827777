import {RootState} from 'store/store';

export const selectLeaderboardData = (state: RootState) => state.root.leaderboard.data;
export const selectLeaderboardSearchBar = (state: RootState) => state.root.leaderboard.searchBar;
export const selectLeaderboardsListData = (state: RootState) => state.root.leaderboard.leaderboardsListData;
export const selectDisplayedLeaderboard = (state: RootState) => state.root.leaderboard.displayedLeaderboard;
export const selectLeaderboardEntryFromUrl = (state: RootState) => state.root.leaderboard.leaderboardEntryFromUrlData.entry;
export const selectLeaderboardCommonEntities = (state: RootState) => state.root.leaderboard;
export const selectLanguageOptions = (state: RootState) => state.root.leaderboard.languageOptions;
export const selectLeaderboardMode = (state: RootState) => state.root.leaderboard.leaderboardMode;
export const selectDisplayedProgressTracker = (state: RootState) => state.root.leaderboard.displayedProgressTracker;
export const selectUserDataInfo = (state: RootState) => state.root.leaderboard.userDataInfo;
export const selectUserRedirectionData = (state: RootState) => state.root.leaderboard.userRedirectionData;