import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';


interface PortalProps {
  containerElement?: HTMLElement;
  children: ReactNode;
}

const Portal: React.FC<PortalProps> = ({ children, containerElement = document.getElementById('root') }) => {
  useEffect(() => {
    const modalEl = document.createElement('div');
    containerElement?.appendChild(modalEl);

    return () => {
      containerElement?.removeChild(modalEl);
    };
  }, [containerElement]);

  return containerElement ? ReactDOM.createPortal(children, containerElement) : null;
};

export default Portal;