
import { FC, ReactNode, useEffect, useRef, memo } from 'react';

interface IOutsideClickerProps {
  className?: string;
  children: ReactNode;
  onClick: (event: MouseEvent) => void;
}

const OutsideClicker: FC<IOutsideClickerProps> = ({ className, children, onClick }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClick(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClick, ref]);

  return (<div ref={ref} className={className}>{children}</div>)
}

export default memo(OutsideClicker);