import React, {memo} from "react";
import classNames from "classnames";
import {getStylesForHighlightedTableRow, getStylesForTableRow} from "../../../../helpers/get-styles-for-table-row";
import LeaderboardTableHeader from "../../../../../../models/LeaderboardTableHeader";
import {leaderboardTableColumnsClassNamesMap} from "../../LeaderboardTableView";

interface LeaderboardTableRowProps {
	row: Record<string, unknown>,
	isHighlighted: boolean,
	rowIndex: number,
	headers: LeaderboardTableHeader[]
}

const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = ({
	row,
	isHighlighted,
	rowIndex,
	headers
}) => {
	let tableRowStyles = getStylesForTableRow(rowIndex);
	if (isHighlighted) {
		tableRowStyles =
			{...tableRowStyles, ...getStylesForHighlightedTableRow()};
	}


	return (
		<tr
			style={tableRowStyles}
			key={`${row.userName}-${rowIndex}`}
			className={classNames('l-table-row', {'l-is-highlighted': isHighlighted})}>
			{Object.values(row as object).map((cellLabel, idx) => (
				<td key={`${cellLabel}-${idx}`}
					className={`l-table-cell ${leaderboardTableColumnsClassNamesMap[headers[idx].type]}`}>{cellLabel}</td>
			))}
		</tr>
	)
}

export default memo(LeaderboardTableRow)