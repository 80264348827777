import {LeaderboardDisplayHeaderItemApiDto} from "../interfaces/restModels";
import LeaderboardDisplayHeaderItemData from "./LeaderboardDisplayHeaderItemData";
import {LeaderboardDisplayHeaderItemType} from "../constants/leaderboard";

export default class LeaderboardDisplayHeaderItem {
	constructor(
		public readonly type: LeaderboardDisplayHeaderItemType,
		public readonly data: LeaderboardDisplayHeaderItemData,
	) {}


	public static fromJson(json: LeaderboardDisplayHeaderItemApiDto): LeaderboardDisplayHeaderItem {
		const type = LeaderboardDisplayHeaderItemType[json.type];

		return new LeaderboardDisplayHeaderItem(
			type, LeaderboardDisplayHeaderItemData.fromJson(json.data)
		)
	}
}