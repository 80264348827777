
import classNames from "classnames";
import { FC } from "react";
import Skeleton from "../../../Skeleton";

import styles from './AutoComplete.module.scss';

interface SkeletonProps {
  className?: string;
}

const SkeletonAutoCompleteSuggestionsList: FC<SkeletonProps> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Skeleton className={styles.skeleton} />
    </div>
  );
}

export default SkeletonAutoCompleteSuggestionsList;