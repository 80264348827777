import {FC} from 'react';
import styles from "./freeRewardContainer.module.scss";
import CollectionMark from "../../../../../../../../../../../assets/icons/collection-mark-icon.png"

interface FreeRewardContainerProps {
	rewardImagePath?: string,
	isCollected: boolean,
}

const FreeRewardContainer: FC<FreeRewardContainerProps> = ({rewardImagePath, isCollected}) => {

	return (<div className={styles.freeRewardContainer}>
		{rewardImagePath && <div className={styles.freeLabelContainer}>FREE</div>}
		{rewardImagePath && <div className={styles.imageContainer}>
			{isCollected &&
				<img loading={"lazy"} alt={"Collection mark"} className={styles.collectionMarkImage} src={CollectionMark}/>}
			<img loading={"lazy"} className={styles.rewardImage} src={rewardImagePath} alt={"Free track reward"}/>
		</div>}
	</div>)
}

export default (FreeRewardContainer);