import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares: any[] = [sagaMiddleware]; // TODO: change any to interface or type

if (process.env.NODE_ENV === 'development') {
  const reduxLogger = createLogger({
    collapsed: true,
    duration: false,
    timestamp: false,
    level: 'info',
  });
  middlewares.push(reduxLogger);
}

export const store = configureStore({
  middleware: middlewares,
  reducer: rootReducer,
})

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;