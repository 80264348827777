import { FC, memo } from 'react';
import Modal from 'components/Modal/Modal';
import OutsideClicker from 'components/OutsideClicker/OutsideClicker';
import AppStoreBtn from 'assets/images/app-store-btn.svg';
import GooglePlayBtn from 'assets/images/google-play-btn.svg';
import { ReactComponent as CloseIconBtn } from 'assets/icons/close.icon.svg';
import PopupImage from 'assets/images/popup-img.png';

import styles from './DownloadModal.module.scss';


interface DownloadModalProps {
  onClose: () => void;
}

const DownloadModal: FC<DownloadModalProps> = ({ onClose }) => {
  return (
    <Modal>
      <OutsideClicker onClick={onClose}>
        <div className={styles.root}>
          <CloseIconBtn className={styles.closeBtn} onClick={onClose} />
          <div className={styles.body}>
            <div className={styles.imgContainer}>
              <img className={styles.popupImg} alt="Popup mobile" src={PopupImage} />
            </div>
            <div className={styles.content}>
              <div className={styles.contentContainer}>
                <h4 className={styles.title}>Download the App</h4>
                <p className={styles.description}>
                  Start building your collection of the freshest pair of kicks on the planet.
                </p>
                <div className={styles.buttons}>
                  <a
                    className={styles.btnLink}
                    href="https://apps.apple.com/us/app/aglet/id1504522507?ls=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={styles.iconBtn}
                      src={AppStoreBtn}
                      alt="App Store Btn"
                    />
                  </a>
                  <a
                    className={styles.btnLink}
                    href="https://play.google.com/store/apps/details?id=app.aglet.mobile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={styles.iconBtn}
                      src={GooglePlayBtn}
                      alt="App Store Btn"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OutsideClicker>
    </Modal>
  )
}

export default memo(DownloadModal);