import {LeaderboardTableHeaderType} from "../constants/leaderboard";
import {LeaderboardTableHeadersToDisplayApiDto} from "../interfaces/restModels";

export default class LeaderboardTableHeader {
	constructor(
		public readonly name: string,
		public readonly type: LeaderboardTableHeaderType,
		public readonly displayColumnIndex: number,
		public readonly correspondingEntryIndex: number | null
	) {}

	public static fromJson(json: LeaderboardTableHeadersToDisplayApiDto,
		correspondingEntryIndex: number | null): LeaderboardTableHeader {
		const type = LeaderboardTableHeaderType[json.type];
		return new LeaderboardTableHeader(json.name, type, parseInt(json.displayColumnIndex), correspondingEntryIndex)
	}
}