export const ApiRoutes = {
	Root: '/',
	Leaderboard: {
		LeaderboardsList: {
			Root: '/api/leaderboards'
		},
		LeaderboardEntries: {
			Root: '/api/leaderboards/:leaderboardId/entries'
		},
		LeaderboardNeighbours: {
			Root: '/api/leaderboards/:leaderboardId/entries/:entryId/neighbours'
		},
		LeaderboardEntryBySearchText: {
			Root: '/api/leaderboards/:leaderboardId/entry_by_search_text'
		},
		Root: '/leaderboard'
	},
	User: {
		Root: '/api/users/me'
	},
	ProgressTrackerEvent: {
		Results: '/api/users/me/collecting-contest/:contestId/results'
	},
};