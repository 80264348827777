import {LeaderboardDisplayHeaderApiDto} from "../interfaces/restModels";
import LeaderboardDisplayHeaderItem from "./LeaderboardDisplayHeaderItem";
import {LeaderboardDisplayHeaderItemType} from "../constants/leaderboard";
import LeaderboardDisplayHeaderItemData from "./LeaderboardDisplayHeaderItemData";

export default class LeaderboardDisplayHeader {
	constructor(
		public readonly items: LeaderboardDisplayHeaderItem[],
		public readonly backgroundColour: string | undefined,
	) {}

	public getItemData(type: LeaderboardDisplayHeaderItemType): LeaderboardDisplayHeaderItemData | undefined {
		return this.items.find(item => item.type === type)?.data;
	}

	public static fromJson(json: LeaderboardDisplayHeaderApiDto): LeaderboardDisplayHeader {
		return new LeaderboardDisplayHeader(
			json.items.map(LeaderboardDisplayHeaderItem.fromJson), json.backgroundColour
		)
	}
}