import {ProgressTrackerEventUserDataApiDto} from "../interfaces/restModels";

export default class ProgressTrackerEventUserData {
	constructor(
		public readonly collectedNumber: number,
		public readonly hasPremium: boolean,
	) {}

	public static fromJson(json: ProgressTrackerEventUserDataApiDto): ProgressTrackerEventUserData {
		return new ProgressTrackerEventUserData(json.collectedNumber, json.hasPremium)
	}
}