import {FC, memo, useCallback, useMemo} from "react";
import CountryFlag from "components/CountryFlag/CountryFlag";
import Select from "components/Select/Select";
import {Country} from "constants/countries";
import {Language, Languages} from "constants/i18n";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import styles from './LanguageSelect.module.scss';
import {selectLanguageOptions} from "../../../../store/leaderboard/leaderboard.selectors";
import {setLanguageFilter} from "../../../../store/leaderboard/leaderboard.slice";


type SelectedLanguageType = {
	lang: Language;
	countryShortCode: Country;
	label: string;
}

type LanguageOptionParams = {
	countryShortCode: Country;
	fullName: string;
}

interface LanguageSelectProps {
	className?: string;
}

const LanguageSelect: FC<LanguageSelectProps> = ({className}) => {
	const selectedLanguage = useSelector(selectLanguageOptions);
	const dispatch = useDispatch();
	const {i18n} = useTranslation();

	const languageOptions = useMemo(() =>
		Object.entries(Languages).map(([key, value]) => ({
			label: value.shortName,
			value: key,
			params: {
				countryShortCode: value.shortCode,
				fullName: value.fullName,
			}
		})), []);

	const selectClassNames = {
		wrapper: className,
		root: styles.selectRoot,
		selected: styles.selectOptionSelected,
		container: styles.selectContainer,
	};

	const handleSelectedOption = useCallback((option: SelectedLanguageType) => {
		i18n.changeLanguage(option.lang);
		dispatch(setLanguageFilter(option));
	}, [dispatch, i18n]);

	return (
		<Select<LanguageOptionParams>
			classNames={selectClassNames}
			renderOption={({label, value, params}) =>
				params && (
					<li
						key={params.countryShortCode}
						className={styles.selectOption}
						onClick={() => handleSelectedOption({
							lang: value as Language,
							countryShortCode: params.countryShortCode as Country,
							label,
						})}
					>
						<CountryFlag className={styles.countryFlag} shortCode={params.countryShortCode as Country}/>
						<span className={styles.optionLanguageLabel}>{params.fullName}</span>
					</li>
				)
			}
			options={languageOptions}
		>
			<div className={styles.selectedLanguage}>
				<CountryFlag className={styles.countryFlag} shortCode={selectedLanguage.countryShortCode}/>
				<span className={styles.languageLabel}>{selectedLanguage.label}</span>
			</div>
		</Select>
	)
}

export default memo(LanguageSelect);