import {FC, memo, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "components/Select/Select";

import styles from './LeaderboardSelect.module.scss';
import {
	setDisplayedLeaderboard,
	setDisplayedProgressIndicator,
	setLeaderboardMode
} from "../../../../store/leaderboard/leaderboard.slice";
import {
	selectDisplayedLeaderboard,
	selectDisplayedProgressTracker,
	selectLeaderboardCommonEntities,
	selectLeaderboardMode,
	selectLeaderboardsListData
} from "../../../../store/leaderboard/leaderboard.selectors";
import {activeProgressTrackersConfigs} from "../../../../config/progressTrackers/activeProgressTrackersConfigs";
import {LeaderboardMode} from "../../../../constants/leaderboard";
import {ScreenView} from "../../../../constants/layout";

interface LeaderboardSelectProps {
	className?: string;
}

const LeaderboardSelect: FC<LeaderboardSelectProps> = ({className}) => {
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard);
	const displayedProgressTracker = useSelector(selectDisplayedProgressTracker)
	const leaderboardMode = useSelector(selectLeaderboardMode);
	const {list: leaderboardsList} = useSelector(selectLeaderboardsListData);
	const {screenView} = useSelector(selectLeaderboardCommonEntities);

	const dispatch = useDispatch();

	const leaderboardOptions = useMemo(() => {
		const tableOptions = leaderboardsList.map((item) => ({
			label: item.title,
			value: item.id,
		}))

		// const progressIndicatorOptions = screenView === ScreenView.Mobile ?
		// 	activeProgressTrackersConfigs.map((item) => ({
		// 		label: item.name,
		// 		value: item.name
		// 	})) : []

		const progressIndicatorOptions = []

		return [...tableOptions, ...progressIndicatorOptions]
	}, [leaderboardsList, screenView]);

	useEffect(function setLeaderboardModeOnScreenViewChange() {
		//if (screenView === ScreenView.Desktop && leaderboardMode === LeaderboardMode.PROGRESS_TRACKER) {
			dispatch(setLeaderboardMode(LeaderboardMode.TABLE))
			dispatch(setDisplayedLeaderboard(leaderboardsList[0]))
		//}
		//else {
		//	dispatch(setDisplayedProgressIndicator(activeProgressTrackersConfigs[0]))
		//}
	}, [screenView])

	const selectClassNames = {
		wrapper: className,
		root: styles.selectRoot,
		selected: styles.selectOptionSelected,
		container: styles.selectContainer,
	};

	const handleSelectedOption = (value: string) => {
		const selectedLeaderboard = leaderboardsList.find(item => item.id === value) ?? null;
		if (selectedLeaderboard) {
			dispatch(setDisplayedLeaderboard(selectedLeaderboard));
			dispatch(setLeaderboardMode(LeaderboardMode.TABLE))
			return;
		}
		const selectedProgressIndicator = activeProgressTrackersConfigs.find(item => item.name === value) ?? null;
		if (selectedProgressIndicator) {
			dispatch(setDisplayedProgressIndicator(selectedProgressIndicator))
			dispatch(setLeaderboardMode(LeaderboardMode.PROGRESS_TRACKER))
		}
	}

	return (
		<Select
			classNames={selectClassNames}
			renderOption={({label, value}) => (
				<li
					key={value}
					className={styles.selectOption}
					onClick={() => handleSelectedOption(value.toString())}>
					{label}
				</li>
			)}
			options={leaderboardOptions}
		>
      <span className={styles.selectOptionSelected}>
        {displayedLeaderboard?.title ?? displayedProgressTracker?.name ?? ""}
      </span>
		</Select>
	)
}

export default memo(LeaderboardSelect);