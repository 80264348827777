// Examples: 
// 1. 684292 -> 684,292
// 2. 684292.42344 -> 684,292
// 3. 102 -> 102
export const formatNumber = (number?: number, delimiter = ','): string => {

	if (typeof number !== 'number' || isNaN(number)) {
		return delimiter;
	}

	const stringifiedInt = parseInt(number.toString()).toString();
	return stringifiedInt.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};

// Example: weekly_leaderboard  -> weeklyLeaderboard
export const snakeToCamelCase = (str: string) =>
	str.toLowerCase().replace(/([-_][a-z])/g, group =>
		group
			.toUpperCase()
			.replace('-', '')
			.replace('_', '')
	);


export const getSplittedCountryName = (value: string, separator = ',') => {
	const [countryName] = value.split(separator);
	return countryName;
}

export const getRoundedNumberWithSeparatorFromString = (value: string): string | number => {
	return isNaN(parseInt(value)) ? value : Math.round(parseInt(value)).toLocaleString("en-US");
}

export function isDefined<T>(value: T | undefined | null): value is T {
	return <T>value !== undefined && <T>value !== null;
}