import {LeaderboardEntriesDataApiDto} from "../interfaces/restModels";
import LeaderboardEntry from "./LeaderboardEntry";

export default class LeaderboardEntriesData {
	constructor(
		public readonly entries: LeaderboardEntry[],
		public readonly pageNumber: number,
		public readonly pageSize: number,
		public readonly totalPages: number,
		public readonly totalElements: number,
	) {}

	public static fromJson(json: LeaderboardEntriesDataApiDto): LeaderboardEntriesData {
		const entries = json.content.map(LeaderboardEntry.fromJson);
		return new LeaderboardEntriesData(entries, json.pageNumber, json.pageSize, json.totalPages, json.totalElements)
	}
}