import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import desktopViewStyles from "../../views/desktop/DesktopView.module.scss";
import {formatNumber} from "../../../../../../utils/format";
import React from "react";

interface PeakRankTableCellContentProps {
	value: string
}

export const PeakRankTableCellContent: React.FC<PeakRankTableCellContentProps> = ({value}) => {
	return (
		<TableCellContent className={desktopViewStyles.rankPeakCell}>
          <span className={desktopViewStyles.tableCellRankPeak}>
            {formatNumber(parseInt(value))}
          </span>
		</TableCellContent>
	);
}