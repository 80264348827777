export type FooterSectionType = {
  link: string; 
  translation: string;
  icon?: string;
}

export const footerSections = {
  company: {
    title: 'footer.sections.company.title',
    sections: [
      // {
      //   link: '/company/about-us',
      //   translation: 'footer.sections.company.navigation.item1',
      // },
      {
        link: 'https://jobs.lever.co/onlife',
        translation: 'footer.sections.company.navigation.item2',
      },
      // {
      //   link: '/company/for-brands',
      //   translation: 'footer.sections.company.navigation.item3',
      // }
    ]
  },
  resources: {
    title: 'footer.sections.resources.title',
    sections: [
      {
        link: 'https://blog.aglet.app',
        translation: 'footer.sections.resources.navigation.item1',
      },
      {
        link: 'https://help.aglet.app/',
        translation: 'footer.sections.resources.navigation.item2',
      },
      // {
      //   link: '/resources/contact-us',
      //   translation: 'footer.sections.resources.navigation.item3',
      // }
    ]
  },
  privacy: {
    title: 'footer.sections.privacy.title',
    sections: [
      {
        link: 'https://aglet.app/privacy_policy.html',
        translation: 'footer.sections.privacy.navigation.item1',
      },
      {
        link: 'https://aglet.app/terms_of_use.html',
        translation: 'footer.sections.privacy.navigation.item2',
      }
    ]
  },
  followUs: {
    title: 'footer.sections.followUs.title',
    sections: [
      {
        link: 'https://discord.com/invite/ZATppAKGT8',
        translation: 'footer.sections.followUs.navigation.item1',
        icon: 'discord',
      },
      {
        link: 'https://www.instagram.com/agletapp',
        translation: 'footer.sections.followUs.navigation.item2',
        icon: 'instagram',
      },
      {
        link: 'https://twitter.com/agletapp',
        translation: 'footer.sections.followUs.navigation.item3',
        icon: 'twitter',
      }
    ]
  }
};
