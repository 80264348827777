import {FC, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
	selectDisplayedProgressTracker,
	selectUserDataInfo,
	selectUserRedirectionData
} from "../../../../../../../store/leaderboard/leaderboard.selectors";
import Skeleton from "../../../../../../../components/Skeleton/Skeleton";
import styles from "./progressTracker.module.scss"
import ProgressTrackerTree from "./components/ProgressTrackerTree/ProgressTrackerTree";
import {AsyncStatus} from "../../../../../../../constants/http";
import {leaderboardService} from "../../../../../../../api/leaderboard/LeaderboardService";
import ProgressTrackerEventUserData from "../../../../../../../models/ProgressTrackerEventUserData";
import {setLeaderboardMode} from "../../../../../../../store/leaderboard/leaderboard.slice";
import {LeaderboardMode} from "../../../../../../../constants/leaderboard";
import FallingAnimation from "../../../../../../../components/FallingAnimation/FallingAnimation";
import {ScreenView} from "../../../../../../../constants/layout";

interface ProgressTrackerProps {

}

const ProgressTracker: FC<ProgressTrackerProps> = ({}) => {
	const [isHeaderLoaded, setIsHeaderLoaded] = useState<boolean>(false);
	const [userEventData, setUserEventData] = useState<ProgressTrackerEventUserData | null>(null);
	const [wasScrolled, setWasScrolled] = useState<boolean>(false);
	const selectedConfig = useSelector(selectDisplayedProgressTracker);
	const userDataInfo = useSelector(selectUserDataInfo)


	const isLoading = !(selectedConfig && userDataInfo.fetchingStatus === AsyncStatus.Success && userEventData)
	const progressBarRef = useRef<HTMLDivElement>(null)
	const {userToken} = useSelector(selectUserRedirectionData)
	const dispatch = useDispatch();

	useEffect(function centerScrollAtUser() {
		if (progressBarRef.current && (isHeaderLoaded || !selectedConfig?.headerImage)) {
			progressBarRef.current.scrollIntoView({block: "center", behavior: "auto"})
			setWasScrolled(true)
		}
	}, [progressBarRef.current, selectedConfig?.headerImage])

	useEffect(function getEventUserData() {
			if (selectedConfig) {
				leaderboardService.getProgressTrackerEventUserData(
					{userToken: userToken!, eventId: selectedConfig.eventId})
					.then(result => {setUserEventData(result)})
					.catch(() => {
						dispatch(setLeaderboardMode(LeaderboardMode.TABLE))
					})
			}
		},
		[selectedConfig])

	return (
		isLoading ?
			<Skeleton
				className={styles.skeleton}
			/> :
			<div style={{backgroundImage: `url(${selectedConfig.backgroundImage})`}} className={styles.progressTrackerContainer}>
				{(selectedConfig.showFallingAnimation && wasScrolled) &&
					<FallingAnimation fallingAnimationItems={["logo", "sneakers", "customMedium", "customLarge"]}
									  customItems={selectedConfig.fallingAnimationCustomImages}
									  screenView={ScreenView.Mobile}/>}
				{selectedConfig.headerImage &&
					<img onLoad={() => {setIsHeaderLoaded(true)}} className={styles.header} alt={"Progress tracker header"}
						 src={selectedConfig.headerImage}/>}
				<ProgressTrackerTree userEventData={userEventData} progressBarRef={progressBarRef}
									 progressTrackerConfig={selectedConfig}/>
				{/*	ToDo customize skeleton*/}
			</div>
	)
}

export default (ProgressTracker);