import { Country } from './countries';

export enum Language {
  English = 'en',
  Japanese = 'ja'
}

export const Languages = {
  [Language.English]: {
    shortCode: Country.UnitedStates,
    fullName: 'English',
    shortName: 'Eng'
  },
  [Language.Japanese]: {
    shortCode: Country.Japan,
    fullName: '日本語',
    shortName: '日本',
  }
}