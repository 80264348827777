import { FC, memo } from "react";
import classNames from "classnames";
import styles from './CountryTooltip.module.scss';


interface TooltipProps {
  className?: string;
  title: string;
}

const CountryTooltip: FC<TooltipProps> = ({ className, title }) => {
  return (
    <span className={classNames(styles.root, className)}>{title}</span>
  );
}

export default memo(CountryTooltip);
