import {UserDataApiDto} from "../interfaces/restModels";

export default class UserData {
	constructor(
		public readonly avatarUrl: string,
	) {}

	public static fromJson(json: UserDataApiDto): UserData {
		return new UserData(json.avatarUrl)
	}
}