export function getPlayerScoreStageIndex(playerPoints: number, scoreStages: number[]) {
	const sortedScoreStages = [...scoreStages].sort((a, b) => a - b)
	let index = 0;
	while (sortedScoreStages[index + 1] <= playerPoints && index < scoreStages.length - 1) {
		index++
	}
	return index;
}

export function getWhiteVerticalBarHeight(scoreStages: number[], playerScoreStageIndex: number): string {
	return 99 - scoreStages[playerScoreStageIndex] / Math.max(...scoreStages) * 100 + "%";
}