import Axios, {AxiosInstance} from "axios";
import LeaderboardApi from "./leaderboard/leaderboard.api";
import AppSettings from "../models/AppSettings";

class ApiInstance {
	api: Api;

	constructor() {
		this.api = new Api();
	}

	get leaderboardApi(): LeaderboardApi {
		return this.api.leaderboardApi;
	}
}

class Api {
	private readonly axiosInstance: AxiosInstance;
	public readonly leaderboardApi: LeaderboardApi;


	constructor() {
		this.axiosInstance = Api.createInstance();
		this.leaderboardApi = new LeaderboardApi(this.axiosInstance);
	}

	private static createInstance(): AxiosInstance {
		const baseUrl = AppSettings.fromJson(window.__APPLICATION_SETTINGS__).baseUrl;
		const headers: { [key: string]: string } = {};
		const axios = Axios.create({
			timeout: 30000,
			headers: headers,
			baseURL: baseUrl
		})

		axios.interceptors.request.use((config) => {
			return config;
		}, (error) => {
			return Promise.reject(error);
		});


		axios.interceptors.response.use(
			(response) => {
				return response
			},
			async (error) => {
				throw error;
			}
		);
		return axios;
	}
}

export const apiInstance: ApiInstance = new ApiInstance();