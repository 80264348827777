import classNames from "classnames";
import CountryFlag from "components/CountryFlag/CountryFlag";
import {Country} from "constants/countries";
import {FC, memo} from "react"

import styles from './RedirectedUser.module.scss';
import LeaderboardEntry from "../../../../../../../../models/LeaderboardEntry";
import LeaderboardTableHeader from "../../../../../../../../models/LeaderboardTableHeader";
import {getValueFromColumns} from "../../../leaderboardTableUtils";
import {LeaderboardTableHeaderType} from "../../../../../../../../constants/leaderboard";
import {getRoundedNumberWithSeparatorFromString, isDefined} from "../../../../../../../../utils/format";
import UserAvatarImage from "../../../../../../../../components/UserAvatarImage/UserAvatarImage";

interface RedirectedUserProps {
	entry: LeaderboardEntry;
	headers: LeaderboardTableHeader[]
}

const RedirectedUser: FC<RedirectedUserProps> = ({entry, headers}) => {
	const rank = entry.rank;
	const peakRank = entry.peakRank;
	const rankChange = entry.rankChange
	const userName = getValueFromColumns(LeaderboardTableHeaderType.USERNAME, entry.columns, headers)
	const countryCode = getValueFromColumns(LeaderboardTableHeaderType.COUNTRY, entry.columns, headers)
	const avatarUrl = getValueFromColumns(LeaderboardTableHeaderType.AVATAR, entry.columns, headers)
	const scoreValue = getValueFromColumns(LeaderboardTableHeaderType.NONE, entry.columns, headers)
	const displayedScoreValue = scoreValue && getRoundedNumberWithSeparatorFromString(scoreValue);

	const rankDiffClassName = rankChange === 0 ?
		'withoutChanging' :
		rankChange as number > 0 ?
			'arrow-up' :
			'arrow-down';


	return (
		<div className={styles.root}>
			<div className={styles.label}>Your current rank</div>
			<div className={styles.value}>
				<div className={classNames(styles.column, styles.rankColumn)}>{rank}</div>
				<div className={classNames(styles.column, styles.rankDiffColumn)}>
					<div className={styles[rankDiffClassName]}/>
					{!!rankChange && <span>{Math.abs(rankChange)}</span>}
				</div>
				<div className={classNames(styles.column, styles.userSectionColumn)}>
					<div className={styles.userAvatarColumn}>
						{isDefined(avatarUrl) && <UserAvatarImage
							className={styles.userAvatarImg}
							src={avatarUrl}
							alt="avatar"
							loading="lazy"
						/>}
					</div>
					<div className={styles.userDescriptionColumn}>
						<div className={styles.userName}>{userName}</div>
						{peakRank && <div className={styles.rankPeak}>{`Peak Rank ${peakRank}`}</div>}
					</div>
				</div>
				{isDefined(countryCode) && <div className={classNames(styles.column, styles.countryColumn)}>
					<CountryFlag shortCode={countryCode as Country}/>
				</div>}
				{isDefined(displayedScoreValue) && <div className={classNames(styles.column, styles.scoreColumn)}>
					{displayedScoreValue}
				</div>}
			</div>
		</div>
	);
}

export default memo(RedirectedUser);