import {FC} from 'react';
import styles from "./progressTrackerScoreCircle.module.scss";

interface ProgressTrackerScoreCircleProps {
	value: number
	circleColor?: string;
	textColor?: string,
	isStartingPosition: boolean
}

const ProgressTrackerScoreCircle: FC<ProgressTrackerScoreCircleProps> = ({value, circleColor, textColor, isStartingPosition}) => {
	const inlineStyles = {
		background: circleColor ?? "white",
		color: textColor,
		width: isStartingPosition ? 42 : 48,
	}

	return (<div style={inlineStyles}
				 className={styles.progressTrackerScoreCircle}>
		{value}
	</div>)
}

export default (ProgressTrackerScoreCircle);