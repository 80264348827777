import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as AgletLogo } from 'assets/images/aglet-logo.svg';
import { navigationItems } from 'config/layout/header';
import Button from 'components/Button/Button';
import LanguageSelect from 'components/Select/components/LanguageSelect/LanguageSelect';
import DownloadModal from 'components/Modal/components/DownloadModal/DownloadModal';
import LeaderboardSelect from 'components/Select/components/LeaderboardSelect/LeaderboardSelect';

import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

const Header: FC<HeaderProps> = ({ className }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = useCallback(() => setShowModal(false), []);
  const handleOpenModal = useCallback(() => setShowModal(true), []);

  const navigationItemsElements = useMemo(() =>
    navigationItems.map(({ link, translation }) => (
      <li key={link} className={styles.navigationItem}>
        <a
          className={styles.navLink}
          target="_blank"
          href={link}
          rel="noreferrer"
        >
          {t(translation)}
        </a>
      </li>
    )), [t]);

  return (
    <header className={classNames(styles.root, className)}>
      {showModal && (
        <DownloadModal
          onClose={handleCloseModal}
        />
      )}
      <section className={styles.wrapper}>
        <div className={styles.logo}>
          <AgletLogo />
        </div>
        <nav className={styles.navigation}>
          {navigationItemsElements}
        </nav>
        <div className={styles.actions}>
          <section className={styles.mobileViewSelectSection}>
            <LeaderboardSelect className={styles.leaderboardRootSelect} />
            <LanguageSelect className={styles.languageRootSelect} />
          </section>
          <section className={styles.desktopViewSelectSection}>
            <LeaderboardSelect />
          </section>
          <section className={styles.downloadBtnSection}>
            <Button
              className={styles.downloadBtn}
              onClick={handleOpenModal}
            >
              {t('header.button')}
            </Button>
          </section>
          <section className={styles.desktopViewSelectSection}>
            <LanguageSelect />
          </section>
        </div>
      </section>
    </header>
  )
}

export default memo(Header);