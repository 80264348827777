import {FC, memo, useEffect} from "react";
import {useLeaderboardContext} from "../../../contexts/leaderboard.context";
import {useDispatch, useSelector} from "react-redux";
import {
	selectDisplayedLeaderboard, selectLeaderboardCommonEntities,
	selectLeaderboardData,
	selectLeaderboardEntryFromUrl
} from "../../../../../../../store/leaderboard/leaderboard.selectors";
import {useTranslation} from "react-i18next";
import {loadLeaderboardEntryFromUrl} from "../../../../../../../store/leaderboard/leaderboard.slice";
import classNames from "classnames";
import styles from "./MobileTableView.module.scss";
import LeaderboardHeader, {LeaderboardHeaderImagesSize} from "../../../components/LeaderboardHeader/LeaderboardHeader";
import RedirectedUser from "../../desktop/components/RedirectedUser/RedirectedUser";
import {getFormattedDateString} from "../../../../../../../utils/date";
import LeaderboardPodium from "../../../components/LeaderboardPodium/LeaderboardPodium";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MobileLeaderboardTable from "./components/Leaderboard/MobileLeaderboardTable";

const MobileTableView: FC = () => {
	const {recalculationDate} = useLeaderboardContext();
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard)!;
	const {records: leaderboardDataRecords} = useSelector(selectLeaderboardData);
	const entryFromUrl = useSelector(selectLeaderboardEntryFromUrl);
	const {t} = useTranslation();
	const {userRedirectionData} = useSelector(selectLeaderboardCommonEntities);

	const dispatch = useDispatch();

	useEffect(function fetchEntryFromUrl() {
		if (userRedirectionData.userName && displayedLeaderboard) {
			dispatch(loadLeaderboardEntryFromUrl({
				leaderboardId: displayedLeaderboard.id,
				searchText: userRedirectionData.userName
			}))
		}
	}, [userRedirectionData.userName, displayedLeaderboard, dispatch])


	return (
		<div className={classNames(styles.root, {[styles.isRedirectedMode]: !!userRedirectionData.userName})}>
			{displayedLeaderboard &&
				<LeaderboardHeader displayedLeaderboard={displayedLeaderboard} imagesSize={LeaderboardHeaderImagesSize.SMALL}/>}
			{!!entryFromUrl && <RedirectedUser entry={entryFromUrl} headers={displayedLeaderboard.mobileTableHeadersToDisplay}/>}
			<header className={styles.header}>
				{recalculationDate.isValid() && <div className={styles.latestRecalculationDate}>
					{`${t("main.leaderboardView.recalculationDateLabel")} ${getFormattedDateString(recalculationDate)}`}
				</div>}            </header>

			{displayedLeaderboard && displayedLeaderboard.showPodium && leaderboardDataRecords &&
				<LeaderboardPodium leaderboardDataRecords={leaderboardDataRecords}/>}
			<section className={styles.searchBarSection}>
				{displayedLeaderboard && <SearchBar/>}
			</section>
			<section className={styles.tabContent}>
				<MobileLeaderboardTable leaderboardDataRecords={leaderboardDataRecords}/>
			</section>
		</div>
	)
}

export default memo(MobileTableView);