import {cherryBlossomConfig} from "./cherryBlossom/cherryBlossomConfig";
import {ActiveProgressTrackerNames} from "./ActiveProgressTrackersList";

export type RewardItem = {
	path: string,
	index: number
}

export type AvatarDecorationConfig = {
	path: string,
	size: number,
	bottom: number
}

export type ProgressTrackerConfig = {
	name: ActiveProgressTrackerNames
	freeItems: RewardItem[]
	premiumItems: RewardItem[],
	eventId: string,
	premiumShopUrl: string,
	backgroundImage?: string,
	headerImage?: string,
	scoreStages: number[],
	verticalBarColor?: string,
	progressBarImage?: string,
	avatarDecorationConfig?: AvatarDecorationConfig,
	achievedStageLabelColor?: string,
	circularProgressBarColor?: string,
	fallingAnimationCustomImages?: string[],
	isPremiumPurchaseEnabled: boolean,
	showFallingAnimation: boolean
}


export const activeProgressTrackersConfigs: ProgressTrackerConfig[] = [
	cherryBlossomConfig
]
