import {FC, RefObject} from 'react';
import {ProgressTrackerConfig} from "../../../../../../../../../config/progressTrackers/activeProgressTrackersConfigs";
import styles from "./progressTrackerTree.module.scss";
import {getPlayerScoreStageIndex, getWhiteVerticalBarHeight} from "../../utils/progressTrackerUtils";
import ProgressTrackerTreeItem from "./ProgressTrackerTreeItem/ProgressTrackerTreeItem";
import ProgressTrackerEventUserData from "../../../../../../../../../models/ProgressTrackerEventUserData";

interface ProgressTrackerTreeProps {
	progressTrackerConfig: ProgressTrackerConfig,
	progressBarRef: RefObject<HTMLDivElement>,
	userEventData: ProgressTrackerEventUserData
}

const ProgressTrackerTree: FC<ProgressTrackerTreeProps> = ({progressTrackerConfig, progressBarRef, userEventData}) => {
	const {collectedNumber: playerPoints, hasPremium} = userEventData;
	const playerScoreStageIndex = getPlayerScoreStageIndex(playerPoints, progressTrackerConfig.scoreStages);
	const scoreStages = progressTrackerConfig.scoreStages;
	const playerProgressInPercents = playerPoints / scoreStages[scoreStages.length - 1] * 100
	const verticalBarColor = progressTrackerConfig.verticalBarColor ??
		"linear-gradient(315.22deg, #FF2B77 0%, #FFD900 99.62%)"

	return (<div className={styles.progressTrackerTree}>
		<div className={styles.progressTrackerTreeContainer}>
			<div style={{background: verticalBarColor}} className={styles.verticalColorBar}/>
			<div style={{height: getWhiteVerticalBarHeight(scoreStages, playerScoreStageIndex)}}
				 className={styles.verticalWhiteBar}/>
			<div className={styles.stagesContainer}>
				{progressTrackerConfig.scoreStages.map((scoreStage, index) =>
					<ProgressTrackerTreeItem
						key={scoreStage}
						progressBarRef={progressBarRef}
						progressTrackerConfig={progressTrackerConfig}
						scoreStage={scoreStage}
						index={index}
						playerProgressInPercents={playerProgressInPercents}
						isUserPremium={hasPremium}
						playerScoreStageIndex={playerScoreStageIndex}
						verticalBarColor={verticalBarColor}/>
				).reverse()}
			</div>
		</div>
	</div>)
}

export default (ProgressTrackerTree);