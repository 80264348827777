import {LeaderboardDisplayHeaderItemDataApiDto} from "../interfaces/restModels";

export default class LeaderboardDisplayHeaderItemData {
	constructor(
		public readonly text?: string,
		public readonly textColour?: string,
		public readonly fontSize?: string,
		public readonly smallImageUrl?: string,
		public readonly mediumImageUrl?: string,
		public readonly largeImageUrl?: string,
	) {}


	public static fromJson(json: LeaderboardDisplayHeaderItemDataApiDto): LeaderboardDisplayHeaderItemData {
		return new LeaderboardDisplayHeaderItemData(
			json.text, json.textColour, json.fontSize, json.smallImageUrl, json.mediumImageUrl, json.largeImageUrl
		)
	}
}