import {FC, useState} from 'react';
import {ReactComponent as UserAvatarPlaceholder} from 'assets/images/userAvatarPlaceholder.svg';

interface UserAvatarImageProps {
	src: string,
	key?: string,
	className: string,
	alt: string,
	loading: "eager" | "lazy" | undefined,
}

const UserAvatarImage: FC<UserAvatarImageProps> = (props) => {
	const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);

	const onLoadError = () => {
		setShowPlaceholder(true)
	}

	return showPlaceholder ?
		<UserAvatarPlaceholder {...props}/> :
		<img onError={onLoadError}  {...props} alt={props.alt}/>
}

export default (UserAvatarImage);