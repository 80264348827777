export enum SortDirection {
	ASC = "ASC",
	DESC = "DESC"
}

export enum SortField {
	RANK = "rank"
}

export interface Pagination {
	pageNumber: number;
	pageSize: number;
	sort?: string
}