import { ScreenView, ScreenViewBreakpoints } from "constants/layout";
import { useEffect, useState } from "react";

// TODO: change ScreenView range to Mobiles
const getDevice = (width: number) => 
  width < ScreenViewBreakpoints.SmallTablets ? 
    ScreenView.Mobile : 
    ScreenView.Desktop;
 
export const useViewport = () => {
  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    device: getDevice(window.innerWidth)
  })

  useEffect(() => {
    const handleResize = () => setViewport({
      width: window.innerWidth,
      device: getDevice(window.innerWidth)
    });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { viewport };
};