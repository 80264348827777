import {FC, memo} from "react";
import {ScreenView} from "constants/layout";

import styles from './FallingAnimation.module.scss';
import MobileFallingAnimation from "./mobile/MobileFallingAnimation";

const LOGO_COUNT = 6;
const SNEAKERS_COUNT = 6;

export type FallingAnimationItems = ("logo" | "sneakers" | "customSmall" | "customMedium" | "customLarge")[];

interface FallingAnimationProps {
	logoCount?: number;
	sneakersCount?: number;
	customItems?: string[];
	screenView: ScreenView,
	fallingAnimationItems?: FallingAnimationItems
}

const FallingAnimation: FC<FallingAnimationProps> = ({
	logoCount = LOGO_COUNT,
	sneakersCount = SNEAKERS_COUNT,
	customItems = [],
	fallingAnimationItems = ["logo", "sneakers"],
	screenView
}) => {


	return (
		<div className={styles.fallingAnimationRoot}>
			{screenView === ScreenView.Mobile ?
				<MobileFallingAnimation
					fallingAnimationItems={fallingAnimationItems}
					sneakersCount={sneakersCount}
					customItems={customItems}
					logoCount={logoCount}/> :
				<></>}
		</div>
	)
}

export default memo(FallingAnimation);