import {FC} from 'react';
import styles from "./progressTrackerRewardStage.module.scss"
import FreeRewardContainer from "./FreeRewardContainer/FreeRewardContainer";
import PremiumRewardContainer from "./PremiumRewardContainer/PremiumRewardContainer";

interface ProgressTrackerTreeRewardStageProps {
	children: JSX.Element,
	freeRewardImagePath?: string,
	premiumRewardImagePath?: string,
	isStartingPosition: boolean,
	isUserPremium: boolean,
	isCollected: boolean,
	premiumShopUrl: string,
	isPremiumPurchaseEnabled: boolean
}

const ProgressTrackerTreeRewardStage: FC<ProgressTrackerTreeRewardStageProps> = ({
	children,
	freeRewardImagePath,
	premiumRewardImagePath,
	isStartingPosition,
	isUserPremium,
	isCollected,
	premiumShopUrl,
	isPremiumPurchaseEnabled
}) => {


	return (<div className={styles.rewardStageContainer}>
		{!isStartingPosition && <div className={styles.rewardStageFrame}/>}
		<div className={styles.rewardStageContainerChildren}>
			{children}
		</div>
		{!isStartingPosition && <div className={styles.freeReward}>
			<FreeRewardContainer
				isCollected={isCollected}
				rewardImagePath={freeRewardImagePath}/>
		</div>}
		{!isStartingPosition && <div className={styles.premiumReward}>
			<PremiumRewardContainer
				isPremiumPurchaseEnabled={isPremiumPurchaseEnabled}
				premiumShopUrl={premiumShopUrl}
				isCollected={isCollected && isUserPremium}
				isUserPremium={isUserPremium}
				rewardImagePath={premiumRewardImagePath}/>
		</div>}
	</div>)
}

export default (ProgressTrackerTreeRewardStage);