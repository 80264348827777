import { memo, FC, ReactNode } from 'react';
import Portal from '../Portal/Portal';
import styles from './Modal.module.scss';

interface ModalProps {
  children?: ReactNode;
}

const Modal: FC<ModalProps> = ({ children }) => {
  return (
    <Portal>
      <div className={styles.root}>
        {children}
      </div>
    </Portal>
  )
}

export default memo(Modal);