import {
  ReactNode,
  PropsWithChildren,
  useState,
  useCallback,
  useEffect
} from "react";
import ItemOption from "./components/Option/Option";
import OutsideClicker from '../OutsideClicker/OutsideClicker';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.icon.svg';
import classnames from "classnames";

import styles from './Select.module.scss';

export interface Option<P = unknown> {
  label: string;
  value: string | number;
  params?: P,
}

interface SelectProps<P = unknown> {
  classNames?: {
    wrapper?: string;
    root?: string;
    selected?: string;
    option?: string;
    container?: string;
  };
  options: Option<P>[];
  children?: ReactNode;
  renderOption?: (option: Option<P>, idx: number) => React.ReactNode;
  onClick?: (key: string, value: string | number) => void;
}

function Select<P = unknown>({
  classNames,
  options,
  children,
  renderOption,
  onClick,
}: PropsWithChildren<SelectProps<P>>) {
  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = useCallback(() => setShowOptions((prevState) => !prevState), []);
  const handleCloseOptions = useCallback(() => setShowOptions(false), []);

  useEffect(() => {
    handleCloseOptions();
  }, [children, handleCloseOptions]);

  return (
    <OutsideClicker
      className={classnames(styles.selectWrapper, classNames?.wrapper)}
      onClick={handleCloseOptions}
    >
      <div className={classnames(styles.root, classNames?.root)}>
        <div
          className={classnames(styles.selectedOption, classNames?.selected)}
          onClick={handleShowOptions}
        >
          {children}
          <ArrowDown className={styles.arrowIcon} />
        </div>
        <div
          className={
            classnames(
              styles.containerWrapper,
              classNames?.container,
              { [styles.isShownContainer]: showOptions }
            )
          }>
          <ul className={styles.container}>
            {options.map((option, idx) =>
              renderOption ? renderOption(option, idx) :
                <ItemOption
                  key={option.value}
                  className={classNames?.option}
                  onClick={() => onClick && onClick(option.label, option.value)}
                  value={option.value}
                />
            )}
          </ul>
        </div>
      </div>
    </OutsideClicker>
  )
}

export default Select;
