import { ErrorInfo, Component, ReactNode } from 'react';


interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = { hasError: false };

  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error({
      ErrorBoundary: {
        error,
        info,
      }
    });
    this.setState({ hasError: true });
  }

  public render() {
    return this.state.hasError ? null : this.props.children;
  }
}

export default ErrorBoundary;