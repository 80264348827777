import {FC, memo, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import {AccessMode} from 'constants/leaderboard';
import {ScreenView} from 'constants/layout';

import styles from './Layout.module.scss';
import {selectDisplayedProgressTracker, selectLeaderboardCommonEntities} from "../../store/leaderboard/leaderboard.selectors";

interface LayoutProps {
	children: ReactNode;
}

const Layout: FC<LayoutProps> = ({children}) => {
	const {screenView, userRedirectionData} = useSelector(selectLeaderboardCommonEntities);
	const displayedProgressTracker = useSelector(selectDisplayedProgressTracker)
	const isDirectEntry = userRedirectionData.accessMode === AccessMode.Directly;
	const isDesktopView = screenView === ScreenView.Desktop;
	const isShown = isDirectEntry || isDesktopView;

	return (
		<div className={classNames(styles.root)}>
			{isShown && !displayedProgressTracker && <Header className={styles.header}/>}
			<main className={styles.main}>
				<ErrorBoundary>
					{children}
				</ErrorBoundary>
			</main>
			{isShown && <Footer className={styles.footer}/>}
		</div>
	)
}

export default memo(Layout);