import {FC} from 'react';
import {PodiumUserDataItem} from "../../LeaderboardPodium";
import styles from "./PodiumScoreIndicator.module.scss";
import {getRoundedNumberWithSeparatorFromString} from "../../../../../../../../utils/format";

interface PodiumScoreIndicatorProps {
	userData: PodiumUserDataItem,
	isFirst: boolean
}

const PodiumScoreIndicator: FC<PodiumScoreIndicatorProps> = ({userData, isFirst}) => {
	const rankChange = userData.rankChange;
	const rankChangeToDisplay: string = Math.abs(rankChange) > 9999 ? "9999+" : Math.abs(rankChange).toString()

	return (
		<div className={styles.podiumScoreIndicator}>
			{rankChange !== 0 && <div className={styles.rankChangeContainer}>
				{rankChange > 0 && <div className={styles.arrowUp}/>}
				<div style={{color: rankChange > 0 ? "#7ED321" : "#D0021B"}}
					 className={styles.rankChangeLabel}>{rankChangeToDisplay}</div>
				{rankChange < 0 && <div className={styles.arrowDown}/>}
			</div>}
			<div style={{color: userData.color, fontSize: isFirst ? 18 : 13}}>
				{getRoundedNumberWithSeparatorFromString(userData.score)}
			</div>
		</div>
	)
}

export default (PodiumScoreIndicator);