import TableCellContent from "../../../../components/Table/components/TableCellContent/TableCellContent";
import desktopViewStyles from "../../views/desktop/DesktopView.module.scss";
import React from "react";

interface UsernameTableCellContentProps {
	value: string
}

export const UsernameTableCellContent: React.FC<UsernameTableCellContentProps> = ({value}) => {
	return (
		<TableCellContent className={desktopViewStyles.userNameCell}>
			<div className={desktopViewStyles.playerCellContainer}>
				<span className={desktopViewStyles.tableCellUserName}>{value}</span>
			</div>
		</TableCellContent>
	);
}