import {memo, useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useViewport} from "hooks/useViewport";
import {ScreenView} from "constants/layout";
import DesktopView from "./views/desktop/DesktopView";
import {LeaderboardContext} from "./contexts/leaderboard.context";

import styles from './Leaderboard.module.scss';
import {selectDisplayedLeaderboard, selectLeaderboardData} from "../../../../store/leaderboard/leaderboard.selectors";
import LeaderboardsListItem from "../../../../models/LeaderboardsListItem";
import dayjs from "dayjs";
import {loadLeaderboardEntries, setScreenView} from "../../../../store/leaderboard/leaderboard.slice";
import {getLeaderboardSortQuery} from "../../../../utils/query";
import {SortDirection, SortField} from "../../../../api/leaderboard/common.types";
import MobileView from "./views/mobile/MobileView";

const viewportViews = {
	[ScreenView.Mobile]: () => <MobileView/>,
	[ScreenView.Desktop]: () => <DesktopView/>,
}

const Leaderboard = () => {
	const {viewport} = useViewport();
	const dispatch = useDispatch();
	const displayedLeaderboard: LeaderboardsListItem | null = useSelector(selectDisplayedLeaderboard)
	const {pageSize} = useSelector(selectLeaderboardData);

	const contextValue = useMemo(() => ({
		recalculationDate: dayjs(displayedLeaderboard?.updatedAt ?? null),
		screenView: viewport.device,
		deviceWidth: viewport.width,
	}), [viewport.device, viewport.width, displayedLeaderboard?.updatedAt]);

	useEffect(() => {
		dispatch(setScreenView(viewport.device));
	}, [dispatch, viewport.device]);

	useEffect(() => {
		if (displayedLeaderboard?.id) {
			dispatch(
				loadLeaderboardEntries({
					pageNumber: 0,
					pageSize: pageSize,
					leaderboardId: displayedLeaderboard.id,
					sort: getLeaderboardSortQuery(SortField.RANK, SortDirection.ASC)
				})
			)
		}
	}, [
		pageSize,
		displayedLeaderboard?.id,
		dispatch
	]);

	return (
		<div className={styles.root}>
			<LeaderboardContext.Provider value={contextValue}>
				{viewportViews[viewport.device]()}
			</LeaderboardContext.Provider>
		</div>
	)
}

export default memo(Leaderboard);