export enum AccessMode {
	Redirected = 'REDIRECTED',
	Directly = 'DIRECTLY',
}

export enum LeaderboardTableHeaderType {
	AVATAR = "AVATAR",
	RANK = "RANK",
	RANK_DIFF = "RANK_DIFF",
	USERNAME = "USERNAME",
	PEAK_RANK = "PEAK_RANK",
	COUNTRY = "COUNTRY",
	NONE = "NONE"
}

export enum LeaderboardDisplayHeaderItemType {
	TITLE_IMAGE = "TITLE_IMAGE",
	RULES_IMAGE = "RULES_IMAGE",
	RULES_TEXT = "RULES_TEXT"
}

export enum LeaderboardType {
	CONTINOUS,
	MONTHLY,
	SPECIFIC_DATES
}

export enum LeaderboardPagination {
	Page = 0,
	Size = 50,
}

export enum SearchBarPagination {
	Page = 1,
	Size = 20,
}

export enum LeaderboardMode {
	TABLE = "TABLE",
	PROGRESS_TRACKER = "PROGRESS_TRACKER"
}