import React, {useMemo} from 'react';
import styles from "./circularProgressBar.module.scss"

interface CircularProgressBarProps {
	circleSize: number;
	strokeWidth: number;
	progress: number;
	circleColor?: string;
	strokeColor: string
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
	circleSize,
	strokeWidth,
	progress,
	circleColor,
	strokeColor
}) => {
	const innerStrokeWidth = 2;
	const fullSize = circleSize + strokeWidth * 2 + innerStrokeWidth
	const circleRadius = circleSize / 2
	const strokeRadius = circleRadius + strokeWidth / 2
	const circumference = useMemo(() => 2 * Math.PI * strokeRadius, [strokeRadius]);
	const dashOffset = useMemo(() => {
		const percentRemaining = 100 - progress;
		return percentRemaining / 100 * circumference;
	}, [circumference, progress]);


	return (
		<div style={{width: fullSize, height: fullSize}} className={styles.circleContainer}>
			<div style={{
				width: circleSize,
				top: `calc(50% - ${circleSize / 2}px`,
				left: `calc(50% - ${circleSize / 2}px`,
				borderWidth: innerStrokeWidth,
				background: circleColor
			}}
				 className={styles.circle}>

			</div>
			<svg viewBox={`0 0 ${fullSize} ${fullSize}`}>
				<circle
					name={"outline"}
					cx={circleRadius - strokeWidth - innerStrokeWidth / 2}
					cy={circleRadius + strokeWidth + innerStrokeWidth / 2}
					r={strokeRadius}
					strokeWidth={strokeWidth}
					stroke={strokeColor}
					strokeDasharray={circumference}
					strokeDashoffset={dashOffset}
					transform={`rotate(-90 ${circleRadius} ${circleRadius})`}
					fill="none"
				/>
			</svg>
		</div>
	);
};

export default CircularProgressBar;