import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import dayjs, {Dayjs} from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced)

const baseFormat = "HH:MMa z DD-MMM-YYYY"

export function convertStringToDayjs(dateAsString: string) {
	return dayjs(dateAsString)
}

export function getFormattedDateString(date: Dayjs) {
	//ToDo set proper timezone
	return date.tz("Europe/London").format(baseFormat)
}