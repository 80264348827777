
export enum Country {
  AllWorld = 'ALL',
  Afghanistan = 'AF',
  AlandIslands = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  AmericanSamoa = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  Anguilla = 'AI',
  Antarctica = 'AQ',
  AntiguaAndBarbuda = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  Bolivia = 'BO',
  BonaireSintEustatiusSaba = 'BQ',
  BosniaAndHerzegovina = 'BA',
  Botswana = 'BW',
  BouvetIsland = 'BV',
  Brazil = 'BR',
  BritishIndianOceanTerritory = 'IO',
  BruneiDarussalam = 'BN',
  Bulgaria = 'BG',
  BurkinaFaso = 'BF',
  Burundi = 'BI',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  CapeVerde = 'CV',
  CaymanIslands = 'KY',
  CentralAfricanRepublic = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  ChristmasIsland = 'CX',
  CocosKeelingIslands = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  CongoDemocraticRepublic = 'CD',
  CookIslands = 'CK',
  CostaRica = 'CR',
  CoteDIvoire = 'CI',
  Croatia = 'HR',
  Cuba = 'CU',
  Curaçao = 'CW',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  ElSalvador = 'SV',
  EquatorialGuinea = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  FalklandIslands = 'FK',
  FaroeIslands = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  FrenchGuiana = 'GF',
  FrenchPolynesia = 'PF',
  FrenchSouthernTerritories = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  GuineaBissau = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  HeardIslandMcdonaldIslands = 'HM',
  HolySeeVaticanCityState = 'VA',
  Honduras = 'HN',
  HongKong = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  Iran = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  IsleOfMan = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  Korea = 'KR',
  KoreaDemocraticPeoplesRepublic = 'KP',
  Kosovo = 'XK',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  LaoPeoplesDemocraticRepublic = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  LibyanArabJamahiriya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  Macedonia = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  MarshallIslands = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  Micronesia = 'FM',
  Moldova = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  NewCaledonia = 'NC',
  NewZealand = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  NorfolkIsland = 'NF',
  NorthernMarianaIslands = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  PalestinianTerritory = 'PS',
  Panama = 'PA',
  PapuaNewGuinea = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Pitcairn = 'PN',
  Poland = 'PL',
  Portugal = 'PT',
  PuertoRico = 'PR',
  Qatar = 'QA',
  Reunion = 'RE',
  Romania = 'RO',
  RussianFederation = 'RU',
  Rwanda = 'RW',
  SaintBarthelemy = 'BL',
  SaintHelena = 'SH',
  SaintKittsAndNevis = 'KN',
  SaintLucia = 'LC',
  SaintMartin = 'MF',
  SaintPierreAndMiquelon = 'PM',
  SaintVincentAndGrenadines = 'VC',
  Samoa = 'WS',
  SanMarino = 'SM',
  SaoTomeAndPrincipe = 'ST',
  SaudiArabia = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  SierraLeone = 'SL',
  Singapore = 'SG',
  SintMaarten = 'SX',
  Slovakia = 'SK',
  Slovenia = 'SI',
  SolomonIslands = 'SB',
  Somalia = 'SO',
  SouthAfrica = 'ZA',
  SouthGeorgiaAndSandwichIsl = 'GS',
  SouthSudan = 'SS',
  Spain = 'ES',
  SriLanka = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  SvalbardAndJanMayen = 'SJ',
  Swaziland = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  SyrianArabRepublic = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  TimorLeste = 'TL',
  Togo = 'TG',
  Tokelau = 'TK',
  Tonga = 'TO',
  TrinidadAndTobago = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  TurksAndCaicosIslands = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  UnitedArabEmirates = 'AE',
  UnitedKingdom = 'GB',
  UnitedStates = 'US',
  UnitedStatesOutlyingIslands = 'UM',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  Vietnam = 'VN',
  VirginIslandsBritish = 'VG',
  VirginIslandsUS = 'VI',
  WallisAndFutuna = 'WF',
  WesternSahara = 'EH',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
  NotDefined = 'NOT_DEFINED'
}

export const Countries: {
  [code in Country]: { name: string, flag?: string }
} = {
  [Country.AllWorld]: {
    name: 'All World',
    flag: '/images/countries-flags/all-world.png',
  },
  [Country.Afghanistan]: {
    name: 'Afghanistan',
  },
  [Country.AlandIslands]: {
    name: 'Aland Islands',
  },
  [Country.Albania]: {
    name: 'Albania',
  },
  [Country.Algeria]: {
    name: 'Algeria',
  },
  [Country.AmericanSamoa]: {
    name: 'American Samoa',
  },
  [Country.Andorra]: {
    name: 'Andorra',
  },
  [Country.Angola]: {
    name: 'Angola',
  },
  [Country.Anguilla]: {
    name: 'Anguilla',
  },
  [Country.Antarctica]: {
    name: 'Antarctica',
  },
  [Country.AntiguaAndBarbuda]: {
    name: 'Antigua And Barbuda',
  },
  [Country.Argentina]: {
    name: 'Argentina',
  },
  [Country.Armenia]: {
    name: 'Armenia',
  },
  [Country.Aruba]: {
    name: 'Aruba',
  },
  [Country.Australia]: {
    name: 'Australia',
  },
  [Country.Austria]: {
    name: 'Austria',
  },
  [Country.Azerbaijan]: {
    name: 'Azerbaijan',
  },
  [Country.Bahamas]: {
    name: 'Bahamas',
  },
  [Country.Bahrain]: {
    name: 'Bahrain',
  },
  [Country.Bangladesh]: {
    name: 'Bangladesh',
  },
  [Country.Barbados]: {
    name: 'Barbados',
  },
  [Country.Belarus]: {
    name: 'Belarus',
  },
  [Country.Belgium]: {
    name: 'Belgium',
  },
  [Country.Belize]: {
    name: 'Belize',
  },
  [Country.Benin]: {
    name: 'Benin',
  },
  [Country.Bermuda]: {
    name: 'Bermuda',
  },
  [Country.Bhutan]: {
    name: 'Bhutan',
  },
  [Country.Bolivia]: {
    name: 'Bolivia',
  },
  [Country.BonaireSintEustatiusSaba]: {
    name: 'Bonaire Sint Eustatius Saba',
  },
  [Country.BosniaAndHerzegovina]: {
    name: 'Bosnia And Herzegovina',
  },
  [Country.Botswana]: {
    name: 'Botswana',
  },
  [Country.BouvetIsland]: {
    name: 'Bouvet Island',
  },
  [Country.Brazil]: {
    name: 'Brazil',
  },
  [Country.BritishIndianOceanTerritory]: {
    name: 'British Indian Ocean Territory',
  },
  [Country.BruneiDarussalam]: {
    name: 'Brunei Darussalam',
  },
  [Country.Bulgaria]: {
    name: 'Bulgaria',
  },
  [Country.BurkinaFaso]: {
    name: 'Burkina Faso',
  },
  [Country.Burundi]: {
    name: 'Burundi',
  },
  [Country.Cambodia]: {
    name: 'Cambodia',
  },
  [Country.Cameroon]: {
    name: 'Cameroon',
  },
  [Country.Canada]: {
    name: 'Canada',
  },
  [Country.CapeVerde]: {
    name: 'Cape Verde',
  },
  [Country.CaymanIslands]: {
    name: 'Cayman Islands',
  },
  [Country.CentralAfricanRepublic]: {
    name: 'Central African Republic',
  },
  [Country.Chad]: {
    name: 'Chad',
  },
  [Country.Chile]: {
    name: 'Chile',
  },
  [Country.China]: {
    name: 'China',
  },
  [Country.ChristmasIsland]: {
    name: 'Christmas Island',
  },
  [Country.CocosKeelingIslands]: {
    name: 'Cocos Keeling Islands',
  },
  [Country.Colombia]: {
    name: 'Colombia',
  },
  [Country.Comoros]: {
    name: 'Comoros',
  },
  [Country.Congo]: {
    name: 'Congo',
  },
  [Country.CongoDemocraticRepublic]: {
    name: 'Congo Democratic Republic',
  },
  [Country.CookIslands]: {
    name: 'Cook Islands',
  },
  [Country.CostaRica]: {
    name: 'Costa Rica',
  },
  [Country.CoteDIvoire]: {
    name: 'Cote D Ivoire',
  },
  [Country.Croatia]: {
    name: 'Croatia',
  },
  [Country.Cuba]: {
    name: 'Cuba',
  },
  [Country.Curaçao]: {
    name: 'Curaçao',
  },
  [Country.Cyprus]: {
    name: 'Cyprus',
  },
  [Country.CzechRepublic]: {
    name: 'Czech Republic',
  },
  [Country.Denmark]: {
    name: 'Denmark',
  },
  [Country.Djibouti]: {
    name: 'Djibouti',
  },
  [Country.Dominica]: {
    name: 'Dominica',
  },
  [Country.DominicanRepublic]: {
    name: 'Dominican Republic',
  },
  [Country.Ecuador]: {
    name: 'Ecuador',
  },
  [Country.Egypt]: {
    name: 'Egypt',
  },
  [Country.ElSalvador]: {
    name: 'El Salvador',
  },
  [Country.EquatorialGuinea]: {
    name: 'Equatorial Guinea',
  },
  [Country.Eritrea]: {
    name: 'Eritrea',
  },
  [Country.Estonia]: {
    name: 'Estonia',
  },
  [Country.Ethiopia]: {
    name: 'Ethiopia',
  },
  [Country.FalklandIslands]: {
    name: 'Falkland Islands',
  },
  [Country.FaroeIslands]: {
    name: 'Faroe Islands',
  },
  [Country.Fiji]: {
    name: 'Fiji',
  },
  [Country.Finland]: {
    name: 'Finland',
  },
  [Country.France]: {
    name: 'France',
  },
  [Country.FrenchGuiana]: {
    name: 'French Guiana',
  },
  [Country.FrenchPolynesia]: {
    name: 'French Polynesia',
  },
  [Country.FrenchSouthernTerritories]: {
    name: 'French Southern Territories',
  },
  [Country.Gabon]: {
    name: 'Gabon',
  },
  [Country.Gambia]: {
    name: 'Gambia',
  },
  [Country.Georgia]: {
    name: 'Georgia',
  },
  [Country.Germany]: {
    name: 'Germany',
  },
  [Country.Ghana]: {
    name: 'Ghana',
  },
  [Country.Gibraltar]: {
    name: 'Gibraltar',
  },
  [Country.Greece]: {
    name: 'Greece',
  },
  [Country.Greenland]: {
    name: 'Greenland',
  },
  [Country.Grenada]: {
    name: 'Grenada',
  },
  [Country.Guadeloupe]: {
    name: 'Guadeloupe',
  },
  [Country.Guam]: {
    name: 'Guam',
  },
  [Country.Guatemala]: {
    name: 'Guatemala',
  },
  [Country.Guernsey]: {
    name: 'Guernsey',
  },
  [Country.Guinea]: {
    name: 'Guinea',
  },
  [Country.GuineaBissau]: {
    name: 'Guinea Bissau',
  },
  [Country.Guyana]: {
    name: 'Guyana',
  },
  [Country.Haiti]: {
    name: 'Haiti',
  },
  [Country.HeardIslandMcdonaldIslands]: {
    name: 'Heard Island Mcdonald Islands',
  },
  [Country.HolySeeVaticanCityState]: {
    name: 'Holy See Vatican City State',
  },
  [Country.Honduras]: {
    name: 'Honduras',
  },
  [Country.HongKong]: {
    name: 'Hong Kong',
  },
  [Country.Hungary]: {
    name: 'Hungary',
  },
  [Country.Iceland]: {
    name: 'Iceland',
  },
  [Country.India]: {
    name: 'India',
  },
  [Country.Indonesia]: {
    name: 'Indonesia',
  },
  [Country.Iran]: {
    name: 'Iran',
  },
  [Country.Iraq]: {
    name: 'Iraq',
  },
  [Country.Ireland]: {
    name: 'Ireland',
  },
  [Country.IsleOfMan]: {
    name: 'Isle Of Man',
  },
  [Country.Israel]: {
    name: 'Israel',
  },
  [Country.Italy]: {
    name: 'Italy',
  },
  [Country.Jamaica]: {
    name: 'Jamaica',
  },
  [Country.Japan]: {
    name: 'Japan',
  },
  [Country.Jersey]: {
    name: 'Jersey',
  },
  [Country.Jordan]: {
    name: 'Jordan',
  },
  [Country.Kazakhstan]: {
    name: 'Kazakhstan',
  },
  [Country.Kenya]: {
    name: 'Kenya',
  },
  [Country.Kiribati]: {
    name: 'Kiribati',
  },
  [Country.Korea]: {
    name: 'South Korea',
  },
  [Country.KoreaDemocraticPeoplesRepublic]: {
    name: 'Korea Democratic Peoples Republic',
  },
  [Country.Kosovo]: {
    name: 'Kosovo',
  },
  [Country.Kuwait]: {
    name: 'Kuwait',
  },
  [Country.Kyrgyzstan]: {
    name: 'Kyrgyzstan',
  },
  [Country.LaoPeoplesDemocraticRepublic]: {
    name: 'Lao Peoples Democratic Republic',
  },
  [Country.Latvia]: {
    name: 'Latvia',
  },
  [Country.Lebanon]: {
    name: 'Lebanon',
  },
  [Country.Lesotho]: {
    name: 'Lesotho',
  },
  [Country.Liberia]: {
    name: 'Liberia',
  },
  [Country.LibyanArabJamahiriya]: {
    name: 'Libyan Arab Jamahiriya',
  },
  [Country.Liechtenstein]: {
    name: 'Liechtenstein',
  },
  [Country.Lithuania]: {
    name: 'Lithuania',
  },
  [Country.Luxembourg]: {
    name: 'Luxembourg',
  },
  [Country.Macao]: {
    name: 'Macao',
  },
  [Country.Macedonia]: {
    name: ' Macedonia',
  },
  [Country.Madagascar]: {
    name: 'Madagascar',
  },
  [Country.Malawi]: {
    name: 'Malawi',
  },
  [Country.Malaysia]: {
    name: 'Malaysia',
  },
  [Country.Maldives]: {
    name: 'Maldives',
  },
  [Country.Mali]: {
    name: 'Mali',
  },
  [Country.Malta]: {
    name: 'Malta',
  },
  [Country.MarshallIslands]: {
    name: 'Marshall Islands',
  },
  [Country.Martinique]: {
    name: 'Martinique',
  },
  [Country.Mauritania]: {
    name: 'Mauritania',
  },
  [Country.Mauritius]: {
    name: 'Mauritius',
  },
  [Country.Mayotte]: {
    name: 'Mayotte',
  },
  [Country.Mexico]: {
    name: 'Mexico',
  },
  [Country.Micronesia]: {
    name: 'Micronesia',
  },
  [Country.Moldova]: {
    name: 'Moldova',
  },
  [Country.Monaco]: {
    name: 'Monaco',
  },
  [Country.Mongolia]: {
    name: 'Mongolia',
  },
  [Country.Montenegro]: {
    name: 'Montenegro',
  },
  [Country.Montserrat]: {
    name: 'Montserrat',
  },
  [Country.Morocco]: {
    name: 'Morocco',
  },
  [Country.Mozambique]: {
    name: 'Mozambique',
  },
  [Country.Myanmar]: {
    name: 'Myanmar',
  },
  [Country.Namibia]: {
    name: 'Namibia',
  },
  [Country.Nauru]: {
    name: 'Nauru',
  },
  [Country.Nepal]: {
    name: 'Nepal',
  },
  [Country.Netherlands]: {
    name: 'Netherlands',
  },
  [Country.NewCaledonia]: {
    name: 'New Caledonia',
  },
  [Country.NewZealand]: {
    name: 'New Zealand',
  },
  [Country.Nicaragua]: {
    name: 'Nicaragua',
  },
  [Country.Niger]: {
    name: 'Niger',
  },
  [Country.Nigeria]: {
    name: 'Nigeria',
  },
  [Country.Niue]: {
    name: 'Niue',
  },
  [Country.NorfolkIsland]: {
    name: 'Norfolk Island',
  },
  [Country.NorthernMarianaIslands]: {
    name: 'Northern Mariana Islands',
  },
  [Country.Norway]: {
    name: 'Norway',
  },
  [Country.Oman]: {
    name: 'Oman',
  },
  [Country.Pakistan]: {
    name: 'Pakistan',
  },
  [Country.Palau]: {
    name: 'Palau',
  },
  [Country.PalestinianTerritory]: {
    name: 'Palestinian Territory',
  },
  [Country.Panama]: {
    name: 'Panama',
  },
  [Country.PapuaNewGuinea]: {
    name: 'Papua New Guinea',
  },
  [Country.Paraguay]: {
    name: 'Paraguay',
  },
  [Country.Peru]: {
    name: 'Peru',
  },
  [Country.Philippines]: {
    name: 'Philippines',
  },
  [Country.Pitcairn]: {
    name: 'Pitcairn',
  },
  [Country.Poland]: {
    name: 'Poland',
  },
  [Country.Portugal]: {
    name: 'Portugal',
  },
  [Country.PuertoRico]: {
    name: 'Puerto Rico',
  },
  [Country.Qatar]: {
    name: 'Qatar',
  },
  [Country.Reunion]: {
    name: 'Reunion',
  },
  [Country.Romania]: {
    name: 'Romania',
  },
  [Country.RussianFederation]: {
    name: 'Russian Federation',
  },
  [Country.Rwanda]: {
    name: 'Rwanda',
  },
  [Country.SaintBarthelemy]: {
    name: 'Saint Barthelemy',
  },
  [Country.SaintHelena]: {
    name: 'Saint Helena',
  },
  [Country.SaintKittsAndNevis]: {
    name: 'Saint Kitts And Nevis',
  },
  [Country.SaintLucia]: {
    name: 'Saint Lucia',
  },
  [Country.SaintMartin]: {
    name: 'Saint Martin',
  },
  [Country.SaintPierreAndMiquelon]: {
    name: 'Saint Pierre And Miquelon',
  },
  [Country.SaintVincentAndGrenadines]: {
    name: 'Saint Vincent And Grenadines',
  },
  [Country.Samoa]: {
    name: 'Samoa',
  },
  [Country.SanMarino]: {
    name: 'San Marino',
  },
  [Country.SaoTomeAndPrincipe]: {
    name: 'Sao Tome And Principe',
  },
  [Country.SaudiArabia]: {
    name: 'Saudi Arabia',
  },
  [Country.Senegal]: {
    name: 'Senegal',
  },
  [Country.Serbia]: {
    name: 'Serbia',
  },
  [Country.Seychelles]: {
    name: 'Seychelles',
  },
  [Country.SierraLeone]: {
    name: 'Sierra Leone',
  },
  [Country.Singapore]: {
    name: 'Singapore',
  },
  [Country.SintMaarten]: {
    name: 'Sint Maarten',
  },
  [Country.Slovakia]: {
    name: 'Slovakia',
  },
  [Country.Slovenia]: {
    name: 'Slovenia',
  },
  [Country.SolomonIslands]: {
    name: 'Solomon Islands',
  },
  [Country.Somalia]: {
    name: 'Somalia',
  },
  [Country.SouthAfrica]: {
    name: 'South Africa',
  },
  [Country.SouthGeorgiaAndSandwichIsl]: {
    name: 'South Georgia And Sandwich Isl',
  },
  [Country.SouthSudan]: {
    name: 'South Sudan',
  },
  [Country.Spain]: {
    name: 'Spain',
  },
  [Country.SriLanka]: {
    name: 'Sri Lanka',
  },
  [Country.Sudan]: {
    name: 'Sudan',
  },
  [Country.Suriname]: {
    name: 'Suriname',
  },
  [Country.SvalbardAndJanMayen]: {
    name: 'Svalbard And Jan Mayen',
  },
  [Country.Swaziland]: {
    name: 'Swaziland',
  },
  [Country.Sweden]: {
    name: 'Sweden',
  },
  [Country.Switzerland]: {
    name: 'Switzerland',
  },
  [Country.SyrianArabRepublic]: {
    name: 'Syrian Arab Republic',
  },
  [Country.Taiwan]: {
    name: 'Taiwan',
  },
  [Country.Tajikistan]: {
    name: 'Tajikistan',
  },
  [Country.Tanzania]: {
    name: 'Tanzania',
  },
  [Country.Thailand]: {
    name: 'Thailand',
  },
  [Country.TimorLeste]: {
    name: 'Timor Leste',
  },
  [Country.Togo]: {
    name: 'Togo',
  },
  [Country.Tokelau]: {
    name: 'Tokelau',
  },
  [Country.Tonga]: {
    name: 'Tonga',
  },
  [Country.TrinidadAndTobago]: {
    name: 'Trinidad And Tobago',
  },
  [Country.Tunisia]: {
    name: 'Tunisia',
  },
  [Country.Turkey]: {
    name: 'Turkey',
  },
  [Country.Turkmenistan]: {
    name: 'Turkmenistan',
  },
  [Country.TurksAndCaicosIslands]: {
    name: 'Turks And Caicos Islands',
  },
  [Country.Tuvalu]: {
    name: 'Tuvalu',
  },
  [Country.Uganda]: {
    name: 'Uganda',
  },
  [Country.Ukraine]: {
    name: 'Ukraine',
  },
  [Country.UnitedArabEmirates]: {
    name: 'United Arab Emirates',
  },
  [Country.UnitedKingdom]: {
    name: 'United Kingdom',
  },
  [Country.UnitedStates]: {
    name: 'United States',
  },
  [Country.UnitedStatesOutlyingIslands]: {
    name: 'United States Outlying Islands',
  },
  [Country.Uruguay]: {
    name: 'Uruguay',
  },
  [Country.Uzbekistan]: {
    name: 'Uzbekistan',
  },
  [Country.Vanuatu]: {
    name: 'Vanuatu',
  },
  [Country.Venezuela]: {
    name: 'Venezuela',
  },
  [Country.Vietnam]: {
    name: 'Vietnam',
  },
  [Country.VirginIslandsBritish]: {
    name: 'Virgin Islands British',
  },
  [Country.VirginIslandsUS]: {
    name: 'Virgin Islands US',
  },
  [Country.WallisAndFutuna]: {
    name: 'Wallis And Futuna',
  },
  [Country.WesternSahara]: {
    name: 'Western Sahara',
  },
  [Country.Yemen]: {
    name: 'Yemen',
  },
  [Country.Zambia]: {
    name: 'Zambia',
  },
  [Country.Zimbabwe]: {
    name: 'Zimbabwe',
  },
  [Country.NotDefined]: {
    name: '???',
    flag: '',
  }
}
