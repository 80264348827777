import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
	memo,
	KeyboardEvent
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {AUTOCOMPLETE_SEARCH_DELAY} from 'constants/delay';
import {SelectedOptionType} from 'constants/autocomplete';
import {Option} from 'interfaces/autocomplete.interface';
import {KeyCodeNames} from 'constants/events';

import styles from './AutoComplete.module.scss';
import {useSelector} from "react-redux";
import {selectDisplayedLeaderboard} from "../../store/leaderboard/leaderboard.selectors";

interface AutoCompleteProps {
	delay?: number;
	className?: string;
	selectedOption: any;
	options: Option[];
	onSelect: (value: SelectedOptionType) => void;
	onChange: (value: string) => void;
	onClose: () => void;
	render: (props: RenderProps) => React.ReactNode;
}

interface InputRenderProps {
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
	onClose: () => void;
}

interface DropdownRenderProps {
	options: Option[];
	activeOptionIndex: number;
	isShownSuggestions: boolean;
	onChangeActiveIndex: (idx: number) => void;
	onClick: (data: { select: any; search: string; }) => void;
}

export interface RenderProps {
	input: InputRenderProps;
	dropdown: DropdownRenderProps;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
	delay = AUTOCOMPLETE_SEARCH_DELAY,
	className,
	selectedOption,
	options,
	onSelect,
	onChange,
	onClose,
	render,
}) => {
	const [searchText, setSearchText] = useState('');
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const {id: leaderboardId} = useSelector(selectDisplayedLeaderboard)!;
	const selectedOptionLabel = useMemo(() => options.find(option => option.value === selectedOption)?.label, [selectedOption])

	const debouncedChange = useMemo(() => _.debounce((value) => onChange(value), delay),
		[onChange, delay]
	);

	const handleActiveSuggestionIndex = useCallback((idx: number) => setActiveSuggestionIndex(idx), []);

	const handleClose = useCallback(() => {
		setSearchText('');
		onClose();
	}, [onClose]);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const searchValue = event.target.value;

			setSearchText(searchValue);

			// NOTE: start to search players when typed more than 1 symbols
			if (searchValue.length > 1) {
				setActiveSuggestionIndex(0);
				setShowSuggestions(true);
				debouncedChange(searchValue);
			}
			else {
				debouncedChange('');
				setShowSuggestions(false);
			}
		}, [debouncedChange]);

	const handleClick = useCallback((data: { select: any, search: string }) => {
		const {select, search} = data;
		onSelect(select);
		setSearchText(search);
		setActiveSuggestionIndex(0);
		setShowSuggestions(false);
	}, [onSelect]);

	const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case KeyCodeNames.Enter:

				if (options.find(({label}) => label === searchText)) {
					onSelect(searchText);
				}

				setActiveSuggestionIndex(0);
				setShowSuggestions(false);
				break;
			case KeyCodeNames.Up:
			case KeyCodeNames.ArrowUp:
				let decreasedActiveSuggestionIndex = activeSuggestionIndex - 1;

				if (decreasedActiveSuggestionIndex < 0) {
					decreasedActiveSuggestionIndex = options.length - 1;
				}

				if (options.length) {
					const activeOption = options[decreasedActiveSuggestionIndex]
					activeOption && setSearchText(activeOption.label);
				}

				setActiveSuggestionIndex(decreasedActiveSuggestionIndex);
				break;
			case KeyCodeNames.Down:
			case KeyCodeNames.ArrowDown:
				let increasedActiveSuggestionIndex = activeSuggestionIndex + 1;

				if (increasedActiveSuggestionIndex > options.length - 1) {
					increasedActiveSuggestionIndex = 0
				}

				if (options.length) {
					const activeOption = options[increasedActiveSuggestionIndex]
					activeOption && setSearchText(activeOption.label);
				}

				setActiveSuggestionIndex(increasedActiveSuggestionIndex);
				break;
		}
	}, [activeSuggestionIndex, options, searchText, onSelect]);

	const showSuggestionsList = useMemo(() => showSuggestions && !!searchText && !selectedOption, [
		showSuggestions,
		searchText,
		selectedOption,
	]);

	useEffect(() => {
		return () => debouncedChange.cancel();
	}, [debouncedChange]);

	// NOTE: when text is changed and option left checked -> reset selected option
	// Also works as delayed execution
	useEffect(() => {
		let timerId = setTimeout(() => {
			if (selectedOptionLabel && (searchText !== selectedOptionLabel)) {
				onSelect(null);
			}
		});
		return () => clearTimeout(timerId);
	}, [selectedOptionLabel, searchText, onSelect]);

	useEffect(function closeOnLeaderboardChange() {
		handleClose()
	}, [leaderboardId])

	const renderProps = useMemo(() => ({
		input: {
			value: searchText,
			onChange: handleChange,
			onKeyDown: handleKeyDown,
			onClose: handleClose,
		},
		dropdown: {
			isShownSuggestions: showSuggestionsList,
			activeOptionIndex: activeSuggestionIndex,
			options: options,
			onChangeActiveIndex: handleActiveSuggestionIndex,
			onClick: handleClick,
		},
	}), [
		options,
		activeSuggestionIndex,
		searchText,
		showSuggestionsList,
		handleActiveSuggestionIndex,
		handleChange,
		handleClose,
		handleKeyDown,
		handleClick
	]);

	return (
		<div className={
			classNames(
				styles.root,
				className,
				{[styles.rootWithSelect]: showSuggestionsList}
			)
		}>
			{render(renderProps)}
		</div>
	);
};

export default memo(AutoComplete);