import {ScreenView} from "constants/layout";
import {LeaderboardTableHeaderType} from "../../../../../constants/leaderboard";
import {UsernameTableCellContent} from "./desktop/UsernameTableCellContent";
import {AvatarTableCellContent} from "./desktop/AvatarTableCellContent";
import {PeakRankTableCellContent} from "./desktop/PeakRankTableCellContent";
import {
	UsernameMobileTableCellValue,
	UsernameTableCellContent as UsernameMobileTableCellContent
} from "./mobile/UsernameTableCellContent";

import desktopViewStyles from "../views/desktop/DesktopView.module.scss";
import mobileViewStyles from "../views/mobile/table/MobileTableView.module.scss"
import {RankTableCellContent} from "./common/RankTableCellContent";
import {RankDiffTableCellContent} from "./common/RankDiffTableCellContent";
import {CountryTableCellContent} from "./common/CountryTableCellContent";
import {UnrecognizedTableCellContent} from "./common/UnrecognizedTableCellContent";

export const leaderboardTableCellMap = {
	[ScreenView.Desktop]: {
		[LeaderboardTableHeaderType.RANK]:
			(value: string) => <RankTableCellContent styles={desktopViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.RANK_DIFF]:
			(value: string) => <RankDiffTableCellContent styles={desktopViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.USERNAME]:
			(value: string) => <UsernameTableCellContent value={value}/>,
		[LeaderboardTableHeaderType.AVATAR]:
			(value: string) => <AvatarTableCellContent value={value}/>,
		[LeaderboardTableHeaderType.PEAK_RANK]:
			(value: string) => <PeakRankTableCellContent value={value}/>,
		[LeaderboardTableHeaderType.COUNTRY]:
			(value: string) => <CountryTableCellContent styles={desktopViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.NONE]:
			(value: string) => <UnrecognizedTableCellContent styles={desktopViewStyles} value={value}/>,
	},
	[ScreenView.Mobile]: {
		[LeaderboardTableHeaderType.RANK]:
			(value: string) => <RankTableCellContent styles={mobileViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.RANK_DIFF]:
			(value: string) => <RankDiffTableCellContent styles={mobileViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.USERNAME]:
			(value: UsernameMobileTableCellValue) => <UsernameMobileTableCellContent value={value}/>,
		[LeaderboardTableHeaderType.COUNTRY]:
			(value: string) => <CountryTableCellContent showCountryName={false} styles={mobileViewStyles} value={value}/>,
		[LeaderboardTableHeaderType.NONE]:
			(value: string) => <UnrecognizedTableCellContent styles={mobileViewStyles} value={value}/>,
	}
}