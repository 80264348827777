import {FC, memo, useCallback, useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import styles from './SearchBar.module.scss';
import {selectDisplayedLeaderboard, selectLeaderboardSearchBar} from "../../../../../../store/leaderboard/leaderboard.selectors";
import {
	loadLeaderboardSearchedEntries, loadLeaderboardSearchResultEntries,
	resetLeaderboardSearchBarData, resetLeaderboardSearchResultEntries,
	setLeaderboardSearchBarSearchValue,
	setLeaderboardSearchBarSelectedValue
} from "../../../../../../store/leaderboard/leaderboard.slice";
import {LeaderboardEntryOptions, LeaderboardOptions} from "../../../../../../api/leaderboard/leaderboard.types";
import {getLeaderboardSortQuery} from "../../../../../../utils/query";
import {SortDirection, SortField} from "../../../../../../api/leaderboard/common.types";
import AutoComplete from "../../../../../../components/AutoComplete/AutoComplete";
import SuggestionsList from "../../../../../../components/AutoComplete/Leaderboard/SuggestionsList/SuggestionsList";
import LeaderboardAutoComplete from "../../../../../../components/AutoComplete/Leaderboard/Leaderboard";

interface SearchBarProps {
	className?: string;
}

const SearchBar: FC<SearchBarProps> = ({className}) => {
	const leaderboardSearchBar = useSelector(selectLeaderboardSearchBar);
	const dispatch = useDispatch();
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard);
	const allHeaders = useMemo(() => displayedLeaderboard?.mobileTableHeadersToDisplay ?? [],
		[displayedLeaderboard?.mobileTableHeadersToDisplay]);

	const {search, selected, searchedEntriesData} = leaderboardSearchBar;
	const {records: leaderboardEntriesData, pageSize, pageNumber: recordsPageNumber, fetchingStatus} = searchedEntriesData;
	const {entryId: selectedOption, pageNumber: selectedPageNumber} = selected;

	const searchBarOptions = useMemo(() =>
			(leaderboardEntriesData?.entries ?? []).map((entry) => ({
				value: entry.id,
				label: entry.identifyingColumn,
				params: {
					...entry
				}
			}))
		, [leaderboardEntriesData?.entries]);

	const calculatedPageNumber = useMemo(() => selectedPageNumber ?? recordsPageNumber, [selectedPageNumber, recordsPageNumber]);

	const handleSelect = useCallback((value: string | null) => {
		dispatch(setLeaderboardSearchBarSearchValue(value ?? ''));
		dispatch(setLeaderboardSearchBarSelectedValue(value));
	}, [dispatch]);

	const handleChange = useCallback((value: string) => {
		dispatch(setLeaderboardSearchBarSearchValue(value));
		if (!value.length) {
			dispatch(resetLeaderboardSearchBarData());
		}
	}, [dispatch]);

	const handleClose = useCallback(() => {
		dispatch(setLeaderboardSearchBarSearchValue(''));
		dispatch(setLeaderboardSearchBarSelectedValue(null));
		dispatch(resetLeaderboardSearchResultEntries())
	}, [dispatch]);


	const handleSearchRecords = useCallback(() => {
		if (calculatedPageNumber && search) {
			const payload: LeaderboardOptions = {
				pageNumber: 0,
				leaderboardId: displayedLeaderboard!.id,
				search: search,
				pageSize: 10,
				sort: getLeaderboardSortQuery(SortField.RANK, SortDirection.ASC)
			};
			dispatch(loadLeaderboardSearchedEntries(payload));
		}
	}, [
		pageSize,
		calculatedPageNumber,
		search,
		dispatch,
	]);


	const handleSelectedOption = useCallback(() => {
		if (!!selectedOption) {
			const payload: LeaderboardEntryOptions = {
				leaderboardId: displayedLeaderboard!.id,
				entryId: selectedOption,
			};
			dispatch(loadLeaderboardSearchResultEntries(payload));
		}
	}, [
		dispatch,
		selectedOption,
	]);

	useEffect(() => {
		handleSearchRecords();
	}, [handleSearchRecords]);

	useEffect(() => {
		handleSelectedOption();
	}, [handleSelectedOption]);

	return (
		<div className={classNames(styles.root, className)}>
			<AutoComplete
				render={
					({input: inputProps, dropdown: dropdownProps}) => (
						<LeaderboardAutoComplete
							{...inputProps}
							placeholder="Search leaderboard"
							className={classNames({[styles.isShownSuggestions]: dropdownProps.isShownSuggestions})}>
							<SuggestionsList
								{...dropdownProps}
								headers={allHeaders}
								loadingStatus={fetchingStatus}
								className={styles.suggestions}/>
						</LeaderboardAutoComplete>
					)
				}
				selectedOption={selectedOption}
				className={styles.autoComplete}
				options={searchBarOptions}
				onSelect={handleSelect}
				onChange={handleChange}
				onClose={handleClose}
			/>
		</div>
	)
}

export default memo(SearchBar);