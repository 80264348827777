import {FC, memo, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {LeaderboardPagination} from 'constants/leaderboard';
import LeaderboardTableSkeleton from "components/Skeleton/components/Screens/Table/LeaderboardTableSkeleton";
import {ScreenView} from 'constants/layout';

import styles from './DesktopLeaderboardTable.module.scss';
import {
	selectDisplayedLeaderboard,
	selectLeaderboardData,
	selectLeaderboardSearchBar,
	selectLeaderboardsListData
} from "../../../../../../../../store/leaderboard/leaderboard.selectors";
import LeaderboardTableView from "../../../../../../components/Table/LeaderboardTableView";
import {getStyledTableRecords} from "../../../leaderboardTableUtils";

interface DesktopLeaderboardTableProps {
	isTabletOrSmallDesktop:boolean
}

const DesktopLeaderboardTable: FC<DesktopLeaderboardTableProps> = ({isTabletOrSmallDesktop}) => {


	const leaderboardData = useSelector(selectLeaderboardData);
	const {selected: {entries: leaderboardSearchResultsData, entryId: selectedEntryId}} = useSelector(selectLeaderboardSearchBar)
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard);
	const {fetchingStatus: leaderboardsListFetchingStatus} = useSelector(selectLeaderboardsListData)
	const {fetchingStatus: leaderboardDataFetchingStatus} = useSelector(selectLeaderboardData);

	const headers = useMemo(() => {
		if (isTabletOrSmallDesktop) {
			return displayedLeaderboard?.mobileTableHeadersToDisplay ?? []
		}
		return displayedLeaderboard?.tableHeadersToDisplay ?? []
	}, [displayedLeaderboard?.tableHeadersToDisplay, displayedLeaderboard?.mobileTableHeadersToDisplay, isTabletOrSmallDesktop]);

	const styledTableRecords = getStyledTableRecords(headers, leaderboardSearchResultsData ?? leaderboardData.records?.entries,
		ScreenView.Desktop, selectedEntryId)

	return (
		<div className={styles.root}>
			<LeaderboardTableView
				headers={headers}
				leaderboardDataFetchingStatus={leaderboardDataFetchingStatus}
				leaderboardsListFetchingStatus={leaderboardsListFetchingStatus}
				rows={styledTableRecords}
				renderSkeleton={() => <LeaderboardTableSkeleton rows={LeaderboardPagination.Size}/>}
			/>
		</div>
	)
}

export default memo(DesktopLeaderboardTable);