import { FC, CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './Skeleton.module.scss';

interface SkeletonProps {
  className?: string;
  style?: CSSProperties;
}

const Skeleton: FC<SkeletonProps> = ({ className, ...restProps }) => {
  return (
    <div className={classNames(styles.skeleton, className)} {...restProps} />
  )
}

export default Skeleton;