import {FC, RefObject} from 'react';
import ProgressTrackerTreeRewardStage from "../ProgressTrackerRewardStage/ProgressTrackerRewardStage";
import ProgressTrackerScoreCircle from "../ProgressTrackerScoreCircle/ProgressTrackerScoreCircle";
import {ProgressTrackerConfig} from "../../../../../../../../../../config/progressTrackers/activeProgressTrackersConfigs";
import ProgressTrackerFinalScoreCircle from "../ProgressTrackerFinalScoreCircle/ProgressTrackerFinalScoreCircle";
import styles from "./progressTrackerTreeItem.module.scss";
import HorizontalProgressBar from "../../HorizontalProgressBar/HorizontalProgressBar";

interface ProgressTrackerTreeItemProps {
	index: number,
	isUserPremium: boolean,
	playerScoreStageIndex: number,
	progressTrackerConfig: ProgressTrackerConfig,
	scoreStage: number,
	verticalBarColor: string,
	playerProgressInPercents: number,
	progressBarRef: RefObject<HTMLDivElement>
}

const ProgressTrackerTreeItem: FC<ProgressTrackerTreeItemProps> = ({
	index,
	progressTrackerConfig,
	isUserPremium,
	playerScoreStageIndex,
	scoreStage,
	verticalBarColor,
	playerProgressInPercents,
	progressBarRef
}) => {
	const isPlayerOnStage = index === playerScoreStageIndex;
	const isAbovePlayer = index > playerScoreStageIndex
	const freeRewardImagePath = progressTrackerConfig.freeItems.find(item => item.index === index)?.path;
	const premiumRewardImagePath = progressTrackerConfig.premiumItems.find(item => item.index === index)?.path;
	const isStartingPosition = index === 0;
	const achievedStageLabelColor = progressTrackerConfig.achievedStageLabelColor ?? "white";

	return (
		<ProgressTrackerTreeRewardStage
			isUserPremium={isUserPremium}
			premiumShopUrl={progressTrackerConfig.premiumShopUrl}
			freeRewardImagePath={freeRewardImagePath}
			isPremiumPurchaseEnabled={progressTrackerConfig.isPremiumPurchaseEnabled}
			premiumRewardImagePath={premiumRewardImagePath}
			isStartingPosition={isStartingPosition}
			isCollected={!isAbovePlayer}>
			<div className={styles.stageContent}>
				{index === progressTrackerConfig.scoreStages.length - 1 ?
					<ProgressTrackerFinalScoreCircle
						value={scoreStage}
						playerProgressInPercents={playerProgressInPercents}
						textColor={isAbovePlayer ? undefined : achievedStageLabelColor}
						strokeColor={progressTrackerConfig.circularProgressBarColor}
						circleColor={isAbovePlayer ? undefined : verticalBarColor}/> :
					<ProgressTrackerScoreCircle
						key={scoreStage}
						isStartingPosition={isStartingPosition}
						circleColor={isAbovePlayer ? undefined : verticalBarColor}
						textColor={isAbovePlayer ? undefined : achievedStageLabelColor}
						value={scoreStage}/>}
				{isPlayerOnStage &&
					<div className={styles.progressBar}>
						<HorizontalProgressBar
							progressBarRef={progressBarRef}
							avatarDecorationConfig={progressTrackerConfig.avatarDecorationConfig}
							progressBarImage={progressTrackerConfig.progressBarImage}
						/>
					</div>
				}
			</div>
		</ProgressTrackerTreeRewardStage>
	)
}

export default (ProgressTrackerTreeItem);