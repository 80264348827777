import freeReward2 from "./images/rewards/free/free2.svg";
import freeReward4 from "./images/rewards/free/free4.svg";
import freeReward6 from "./images/rewards/free/free6.png";
import freeReward8 from "./images/rewards/free/free8.svg";
import freeReward10 from "./images/rewards/free/free10.png";
import premiumReward1 from "./images/rewards/premium/premium1.png";
import premiumReward2 from "./images/rewards/premium/premium2.svg";
import premiumReward3 from "./images/rewards/premium/premium3.svg";
import premiumReward5 from "./images/rewards/premium/premium5.png";
import premiumReward6 from "./images/rewards/premium/premium6.svg";
import premiumReward7 from "./images/rewards/premium/premium7.png";
import premiumReward8 from "./images/rewards/premium/premium8.svg";
import premiumReward9 from "./images/rewards/premium/premium9.svg";
import premiumReward10 from "./images/rewards/premium/premium10.png";

import petal1 from "./images/fallingAnimation/Petal1.png";
import petal2 from "./images/fallingAnimation/Petal2.png";
import petal3 from "./images/fallingAnimation/Petal3.png";
import petal4 from "./images/fallingAnimation/Petal4.png";
import petal5 from "./images/fallingAnimation/Petal5.png";
import petal6 from "./images/fallingAnimation/Petal6.png";
import petal7 from "./images/fallingAnimation/Petal7.png";
import petal8 from "./images/fallingAnimation/Petal8.png";
import petal9 from "./images/fallingAnimation/Petal9.png";
import petal10 from "./images/fallingAnimation/Petal10.png";
import petal11 from "./images/fallingAnimation/Petal11.png";
import petal12 from "./images/fallingAnimation/Petal12.png";
import petal13 from "./images/fallingAnimation/Petal13.png";
import petal14 from "./images/fallingAnimation/Petal14.png";
import petal15 from "./images/fallingAnimation/Petal15.png";
import petal16 from "./images/fallingAnimation/Petal16.png";
import petal17 from "./images/fallingAnimation/Petal17.png";
import petal18 from "./images/fallingAnimation/Petal18.png";
import petal19 from "./images/fallingAnimation/Petal19.png";
import petal20 from "./images/fallingAnimation/Petal20.png";
import petal21 from "./images/fallingAnimation/Petal21.png";
import whiteCherryBlossom from "./images/fallingAnimation/Cherry Blossom - White.png";
import pinkCherryBlossom from "./images/fallingAnimation/Cherry Blossom - Pink.png";


import {ActiveProgressTrackerNames} from "../ActiveProgressTrackersList";
import {ProgressTrackerConfig} from "../activeProgressTrackersConfigs";
import HeaderImage from "./images/cherryBlossomHeader.png"
import BackgroundImage from "./images/cherryBlossomBackground.png"
import ProgressBarImage from "./images/cherryBlossomProgressBar.png"
import AvatarDecoration from "./images/cherryBlossomAvatarDecoration.png"

export const cherryBlossomConfig: ProgressTrackerConfig = {
	name: ActiveProgressTrackerNames.CHERRY_BLOSSOM,
	eventId: "cherry-blossom",
	freeItems: [
		{path: freeReward2, index: 2},
		{path: freeReward4, index: 4},
		{path: freeReward6, index: 6},
		{path: freeReward8, index: 8},
		{path: freeReward10, index: 10},
	],
	premiumItems: [
		{path: premiumReward1, index: 1},
		{path: premiumReward2, index: 2},
		{path: premiumReward3, index: 3},
		{path: premiumReward5, index: 5},
		{path: premiumReward6, index: 6},
		{path: premiumReward7, index: 7},
		{path: premiumReward8, index: 8},
		{path: premiumReward9, index: 9},
		{path: premiumReward10, index: 10},

	],
	scoreStages: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
	headerImage: HeaderImage,
	backgroundImage: BackgroundImage,
	verticalBarColor: "linear-gradient(180deg, #EEEEEE 0%, #EC8794 100%)",
	progressBarImage: ProgressBarImage,
	avatarDecorationConfig: {path: AvatarDecoration, size: 80, bottom: -12},
	achievedStageLabelColor: "white",
	premiumShopUrl: "https://aglet.app/mobile/SneakerShop/batch1_AGT00002933",
	circularProgressBarColor: "#F17E80",
	showFallingAnimation: true,
	fallingAnimationCustomImages: [petal1, petal2, petal3, petal4, petal5, petal6, petal7, petal8, petal9, petal10, petal11,
		petal12, petal13, petal14, petal15, petal16, petal17, petal18, petal19, petal20, petal21, whiteCherryBlossom,
		pinkCherryBlossom],
	isPremiumPurchaseEnabled: true
}