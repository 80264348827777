import { DetailedHTMLProps, FC, ImgHTMLAttributes, useMemo, memo } from "react";
import { CircleFlag } from 'react-circle-flags';
import classNames from "classnames";
import { Countries, Country } from "constants/countries";

import styles from './CountryFlag.module.scss';


export interface CountryFlagProps extends DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> {
  shortCode: Country;
}


const CountryFlag: FC<CountryFlagProps> = ({ className, shortCode, alt, ...restProps }) => {
  const renderFlagComponent = useMemo(() => {
    switch (shortCode) {
      case Country.AllWorld:
        return (
          <img
            {...restProps}
            className={classNames(styles.root, className)}
            src={Countries[Country.AllWorld]?.flag}
            alt={alt}
          />
        );
      case Country.NotDefined:
        return (<div className={styles.notDefinedCountry}>?</div>);
      default:
        return (
          <CircleFlag countryCode={shortCode.toLowerCase()} height="30" />
        );
    }
  }, [shortCode, className, alt, restProps]);


  return renderFlagComponent;
}

export default memo(CountryFlag);