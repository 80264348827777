import React, {FC, memo, MouseEvent, useCallback} from 'react';
import classNames from "classnames";
import {AsyncStatus} from 'constants/http';
import SkeletonAutoCompleteSuggestionsList from 'components/Skeleton/components/Screens/AutoComplete/AutoComplete';

import styles from './SuggestionsList.module.scss';
import LeaderboardTableHeader from "../../../../models/LeaderboardTableHeader";
import RenderOption from "./RenderOption/RenderOption";

export interface Option {
	label: string;
	value: string | number;
	params?: { rankChange: number, peakRank: number, rank: number, columns: string[] },
}

interface SuggestionsListProps {
	loadingStatus?: AsyncStatus;
	className?: string;
	activeOptionIndex: number;
	options: Option[];
	isShownSuggestions?: boolean;
	onChangeActiveIndex: (idx: number) => void;
	onClick: (data: any) => void;
	headers: LeaderboardTableHeader[];
}

const SuggestionsList: FC<SuggestionsListProps> = ({
	className,
	loadingStatus,
	options,
	isShownSuggestions,
	activeOptionIndex,
	onChangeActiveIndex,
	headers,
	onClick,
}) => {
	const handleMouseOver = useCallback((e: MouseEvent<HTMLUListElement>) => {
		const hoveredOption = (e.target as HTMLLIElement).getAttribute('data-id');
		const foundIndex = options.findIndex((option) => option.value === hoveredOption);

		if (foundIndex !== -1) {
			onChangeActiveIndex(foundIndex);
		}
	}, [options, onChangeActiveIndex]);


	return (
		<div className={
			classNames(
				styles.container,
				{[styles.isVisible]: isShownSuggestions},
				{[styles.isShownNothingToShow]: isShownSuggestions && !options.length})}>
			{
				loadingStatus === AsyncStatus.Pending ? (
					<SkeletonAutoCompleteSuggestionsList className={styles.skeleton}/>
				) : (
					<ul
						className={classNames(styles.root, className)}
						onMouseOver={handleMouseOver}>
						{
							options.length ?
								options.map((option, idx) => (
									<RenderOption key={option.value} className={styles.option}
												  isActive={activeOptionIndex === idx}
												  onClick={onClick} headers={headers} {...option}/>
								)) :
								<li className={styles.nothingToShow}>Nothing To Show</li>
						}
					</ul>
				)
			}
		</div>
	)
}

export default memo(SuggestionsList);