import {FC, memo, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {LeaderboardPagination, LeaderboardTableHeaderType} from 'constants/leaderboard';
import LeaderboardTableSkeleton from "components/Skeleton/components/Screens/Table/LeaderboardTableSkeleton";
import {ScreenView} from 'constants/layout';

import styles from './MobileLeaderboardTable.module.scss';
import LeaderboardTableView from "../../../../../../../components/Table/LeaderboardTableView";
import {
	selectDisplayedLeaderboard,
	selectLeaderboardData, selectLeaderboardSearchBar,
	selectLeaderboardsListData
} from "../../../../../../../../../store/leaderboard/leaderboard.selectors";
import {getStyledTableRecords} from "../../../../leaderboardTableUtils";
import LeaderboardEntriesData from "../../../../../../../../../models/LeaderboardEntriesData";

interface MobileLeaderboardTableProps {
	leaderboardDataRecords: LeaderboardEntriesData | null
}

const MobileLeaderboardTable: FC<MobileLeaderboardTableProps> = ({leaderboardDataRecords}) => {
	const displayedLeaderboard = useSelector(selectDisplayedLeaderboard);
	const {selected: {entries: leaderboardSearchResultsData, entryId: selectedEntryId}} = useSelector(selectLeaderboardSearchBar)
	const allHeaders = useMemo(() => displayedLeaderboard?.mobileTableHeadersToDisplay ?? [],
		[displayedLeaderboard?.mobileTableHeadersToDisplay]);

	const separateHeaders = useMemo(() => allHeaders.filter(
			header => header.type !== LeaderboardTableHeaderType.AVATAR && header.type !== LeaderboardTableHeaderType.PEAK_RANK),
		[allHeaders]);


	const {fetchingStatus: leaderboardsListFetchingStatus} = useSelector(selectLeaderboardsListData)
	const {fetchingStatus: leaderboardDataFetchingStatus} = useSelector(selectLeaderboardData);
	const leaderboardEntries = leaderboardDataRecords?.entries;
	const leaderboardEntriesToDisplay = displayedLeaderboard?.showPodium ? leaderboardEntries?.slice(3) : leaderboardEntries

	const styledTableRecords = getStyledTableRecords(separateHeaders,
		leaderboardSearchResultsData ?? leaderboardEntriesToDisplay, ScreenView.Mobile, selectedEntryId, allHeaders)

	return (
		<div className={styles.root}>
			<LeaderboardTableView
				headers={separateHeaders}
				leaderboardDataFetchingStatus={leaderboardDataFetchingStatus}
				leaderboardsListFetchingStatus={leaderboardsListFetchingStatus}
				rows={styledTableRecords}
				renderSkeleton={() => <LeaderboardTableSkeleton rows={LeaderboardPagination.Size}/>}
			/>
		</div>
	)
}

export default memo(MobileLeaderboardTable);